import reportEditor from 'http/reportEditor';
import { downloadText } from 'util/downloadBlob';
import { readAsBase64 } from 'util/files';

const downloadIXBRL = async (rawIXBRL, filename = 'esg_report.xhtml') => {
    const assetsPattern = new RegExp(reportEditor.getAssetURL('.+?'), 'g');

    // TODO: remove elements server side
    rawIXBRL = rawIXBRL.replace('/*PRINT_HIDE_CLASS_PLACEHOLDER*/', `
        .hideInPrintedVersion {
            opacity: 0 !important;
        }    
    `)

    // find every asset url
    const usedAssets = new Set();
    while (true) {
        const match = assetsPattern.exec(rawIXBRL);
        if (!match) break;
        usedAssets.add(match[0]);
    }

    // replace every asset URL with inline base64
    await Promise.all([...usedAssets].map(async assetURL => {
        const assetResponse = await fetch(assetURL);
        if (!assetResponse.ok) {
            throw new Error(`Got ${assetResponse.status} fetching asset: ${assetURL}`);
        }

        const blob = await assetResponse.blob();
        const base64 = await readAsBase64(blob);

        const assetURLPattern = new RegExp(assetURL, 'g');

        rawIXBRL = rawIXBRL.replace(assetURLPattern, () => {
            return base64;
        });
    }));

    downloadText(filename, 'application/xhtml+xml', rawIXBRL);
};

export const downloadIXBRLDocument = (document, user) => {
    const filename = (
        `${user.displayName}_${document.title}`
        .toLowerCase()
        .split('')
        .map(char => {
            const replace = {
                'æ': 'ae',
                'ø': 'oe',
                'å': 'a',
                ' ': '_',
            };

            if (char in replace) {
                return replace[char];
            }

            if (/[a-z0-9_]/.test(char)) {
                return char;
            };

            return '';
        })
        .join('')
    );

    return downloadIXBRL(document.rawIXBRL, filename);
};

export default downloadIXBRL;