import { toast } from 'react-toastify';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Icon, Loader } from 'semantic-ui-react';
import { prepareDownloadLink, deleteFile, uploadFile } from 'http/file-storage';
import { updateLogo, deleteLogo } from 'http/accounts';
import { readAsImage, base64ToFile, isFile } from 'util/files';
import useUser from 'util/useUser';
import ImageCropper from 'design/atoms/ImageCropper';
import FileuploadButton from 'design/atoms/FileuploadButton';
import ColoredText from 'design/atoms/ColoredText';

const acceptedTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg'];

export const CurrentLogo = ({ onNoLogoChosenClicked }) => {
    const user = useUser();
    const [src, setSrc] = useState('');
    const [fileDraggedOver, setFileDraggedOver] = useState(false);

    useEffect(() => {
        if (!user.logo) return;

        prepareDownloadLink(user.logo)
            .then(link => setSrc(link))
    }, [user.logo]);

    if (!user.logo) {
        return (
            <div
                style={{
                    border: '2px dashed ' + (fileDraggedOver ? 'orange' : 'lightgray'),
                    padding: '2em',
                    borderRadius: '0.5em',
                    textAlign: 'center',
                    width: '100%',
                    cursor: onNoLogoChosenClicked ? 'pointer' : undefined,
                }}
                onClick={() => onNoLogoChosenClicked && onNoLogoChosenClicked()}
                onDragOver={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setFileDraggedOver(true);
                }}
                onDragLeave={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setFileDraggedOver(false);
                }}
                onDrop={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setFileDraggedOver(false);
                    onNoLogoChosenClicked(e.dataTransfer.files[0]);
                }}
            >
                <Icon.Group size='huge'>
                    <Icon name='image outline' />
                    <Icon corner='top right' name='question' />
                </Icon.Group>
                <br />
                {fileDraggedOver ? <span>Slip logo her</span> : <i>Intet logo valgt</i>}
            </div>
        );
    }

    if (!src) {
        return (
            <Loader active inline='centered' />
        );
    }

    return (
        <img
            src={src}
            alt='logo'
            style={{
                maxWidth: '500px',
                maxHeight: '500px',
                border: '1px solid lightgray',
                borderRadius: '0.5em',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            }}
        />
    );
};

export const prepareImageFileForLogoCropping = async file => {
    const image = await readAsImage(file);

    const { naturalWidth, naturalHeight } = image; 

    if (naturalHeight < 150) {
        throw new Error('Billedet skal have en minimumshøjde på 150 pixels');
    }

    const pixelsInImage = naturalWidth * naturalHeight;
    const maxPixels = 1920 * 1080;

    if (pixelsInImage > maxPixels) {
        const scaleFactor = Math.sqrt(maxPixels / pixelsInImage);
        const newWidth = Math.floor(naturalWidth * scaleFactor);
        const newHeight = Math.floor(naturalHeight * scaleFactor);

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, newWidth, newHeight);
        image.src = canvas.toDataURL();
    }

    return image;
};

const UploadLogo = ({ onLogoChanged, onCancel, hideCurrentLogo, promptFileOnMount, defaultImage = null }) => {
    const [mounted, setMounted] = useState(false);
    const uploadRef = useRef();
    const user = useUser();

    const hasCurrentLogo = !!user.logo;

    useLayoutEffect(() => {
        if (mounted) return;
        setMounted(true);

        if (promptFileOnMount && !hasCurrentLogo) {
            uploadRef.current.clickUpload();
        }
    }, [mounted, promptFileOnMount, hasCurrentLogo]);

    const [imageBeingCropped, setImageBeingCropped] = useState(defaultImage);
    const [latestCrop, setLatestCrop] = useState(null);
    const [working, setWorking] = useState(false);

    const onLogoSelected = async file => {
        if (!isFile(file)) {
            return;
        }
        
        try {
            const image = await prepareImageFileForLogoCropping(file);
            setImageBeingCropped(image);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const saveLogo = async () => {
        const { height, data } = latestCrop;

        if (height < 150) {
            return toast.error('Udsnittet skal have en højde på mindst 150 pixels');
        }
        
        setWorking(true);

        try {
            const file = await base64ToFile(data, 'logo.png', 'image/png');

            const formData = new FormData();
            formData.append('file', file);

            // delete existing logo
            if (user.logo) {
                await deleteFile(user.logo);
            }

            const logoFileID = await uploadFile(file);

            // Save file ID reference on user
            await updateLogo(logoFileID);

            setImageBeingCropped(null);
            setLatestCrop(null);
            onLogoChanged && onLogoChanged();
            toast.success('Logo gemt');
        } catch (e) {
            console.error('Error saving logo: ' + e.message);
            toast.error('Logo blev ikke gemt');
        } finally {
            setWorking(false);
        }
    };
    
    const removeLogo = async () => {
        setWorking(true);
        try {
            await deleteFile(user.logo);
            await deleteLogo();
            onLogoChanged && onLogoChanged();
            toast.success('Logo blev fjernet');
        } catch {
            toast.error('Logo blev ikke fjernet');
        }
        setWorking(false);
    };

    let content;
    let options;

    if (imageBeingCropped) {
        content = (
            <div style={{ maxWidth: '1024px' }}>
                <ImageCropper
                    image={imageBeingCropped}
                    onCrop={cropped => setLatestCrop(cropped)}
                />
            </div>
        );

        options = (
            <>
                <ColoredText
                    onClick={() => {
                        setLatestCrop(null);
                        setImageBeingCropped(null);
                        onCancel && onCancel();
                    }}
                    disabled={working}
                    color='black'
                    icon='arrow left'
                    iconPosition='left'
                    content='Annullér'
                    underlined={false}
                    link
                />
                <Button
                    onClick={saveLogo}
                    disabled={working}
                    content='Gem logo'
                    icon='save'
                    primary
                />
            </>
        );
    } else {
        content = !hideCurrentLogo && (
            <CurrentLogo
                onNoLogoChosenClicked={file => {
                    if (file) {
                        onLogoSelected(file);
                    } else {
                        uploadRef.current.clickUpload();
                    }
                }}
            />
        );

        options = (
            <>
                {user.logo && (
                    <ColoredText
                        onClick={removeLogo}
                        underlined={false}
                        icon='trash'
                        iconPosition='left'
                        content='Fjern logo'
                        disabled={working}
                        link
                    />
                )}
                <FileuploadButton
                    ref={uploadRef}
                    onChange={onLogoSelected}
                    disabled={working}
                    accept={acceptedTypes}
                    trigger={
                        <ColoredText
                            underlined={false}
                            icon='upload'
                            iconPosition='left'
                            content='Upload nyt logo'
                            disabled={working}
                            link
                        />
                    }
                />
            </>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '1em',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {content}
            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                {options}
            </div>
        </div>
    );
}

export default UploadLogo;