import { useMemo } from 'react';
import { Divider, Loader, Segment } from 'semantic-ui-react';
import ColoredText from 'design/atoms/ColoredText';
import SegmentMenuItem from 'design/atoms/SegmentMenuItem';
import StepsSideMenu from './StepsSideMenu';
import FieldGroup from 'design/atoms/FieldGroup';

const IXBRLYearReportViewer = ({ selectedDocument, documents, selectedDocumentIndex, setSelectedDocumentIndex, documentWrapper, contentIsReady, ...props }) => {
    const stickyOffsetPixels = 145;
    const menuHeightPixels = 40;

    const pages = useMemo(() => {
        if (!selectedDocument) return [];

        const parser = new DOMParser();
        const doc = parser.parseFromString(selectedDocument.rawIXBRL, 'application/xhtml+xml');
        const allPageTitles = doc.querySelectorAll('.page-title');
        return [...allPageTitles].map(page => {
            return {
                pageID: page.parentElement.id,
                pageTitle: page.innerText,
            };
        });
    }, [selectedDocument]);

    const setSelectedDocumentIndexAndScrollToTop = documentIndex => {
        setSelectedDocumentIndex(documentIndex);
        
        const topOfDocument = stickyOffsetPixels + menuHeightPixels;
        if (window.scrollY > topOfDocument) {
            window.scrollTo(0, topOfDocument);
        }
    };

    const renderLoadingPage = () => {
        const pageInnerWidthMm = 209;
        const pagePaddingMm = 9; 
        return (
            <div
                style={{ width: `${pageInnerWidthMm + pagePaddingMm * 2}mm` }}
            >
                <Loader inline='centered' active size='huge' />
            </div>
        );
    };

    const renderTableOfContents = () => {
        const renderedPages = pages.map(({ pageTitle, pageID }, idx) => {
            const onClick = () => {
                const element = document.getElementById(pageID);
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: elementPosition - (stickyOffsetPixels + menuHeightPixels),
                    behavior: 'smooth',
                });
            };

            const isLast = idx === pages.length - 1;

            return (
                <div>
                    <ColoredText underlined={false} key={pageID} onClick={onClick} content={pageTitle} link />
                    {!isLast && <Divider style={{ margin: 0 }} />}
                </div>
            );
        });

        if (renderedPages.length === 0) {
            return null;
        }

        return (
            <FieldGroup title='Indholdsfortegnelse' collapsible defaultOpen={true}>
                {renderedPages}
            </FieldGroup>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'columm', gap: '1em' }}>
            <div style={{ flex: 1 }}>
                <div style={{ position: 'sticky', top: stickyOffsetPixels + 'px' }}>
                    <StepsSideMenu selectedDocument={selectedDocument} documents={documents} {...props} />
                    {renderTableOfContents()}
                </div>
            </div>
            <div>
                {documents.length > 1 && (
                    <Segment.Group horizontal style={{ marginTop: 0, position: 'sticky', top: stickyOffsetPixels + 'px', zIndex: 1 }}>
                        {documents.map((document, documentIndex) => {
                            const active = documentIndex === selectedDocumentIndex;
                            return (
                                <SegmentMenuItem
                                    active={active}
                                    onClick={() => setSelectedDocumentIndexAndScrollToTop(documentIndex)}
                                    content={document.title}
                                />
                            );
                        })}
                    </Segment.Group>
                )}
                {documentWrapper}
                {!contentIsReady && renderLoadingPage()}
            </div>
        </div>
    );
};

export default IXBRLYearReportViewer;