import { statusesMap } from "design/molecules/AppLauncher/userStatuses";

const makeStatusResponse = (defaultPropsToOverwrite = {}) => {
    const status = {
        icon: '',
        color: '',
        title: '',
        flowDescription: '',
        isSigned: false,
    };

    Object.assign(status, defaultPropsToOverwrite);

    return status;
};

const isAwaitingDocuments = flow => {
    if (flow.status === 'FinalizationPending') {
        return true;
    }

    if (flow.status === 'Active') {
        const everyStepIsCompleted = flow.steps.every(step => {
            const signeesInStepHaveSigned = step.signees.every(signee => {
                return signee.status === 'Completed';
            });

            return signeesInStepHaveSigned;
        });

        if (everyStepIsCompleted) {
            return true;
        }
    }

    return false;
};

const isCompletionStuck = (flow) => {
    if (flow.status !== 'Completed') {
        return false;
    }

    if (flow.completionHandled) {
        return false;
    }

    return true;
};

const describeFlowStatus = (flow, productStatus) => {
    if (!flow || !flow.status) {
        if (productStatus && productStatus.step >= statusesMap.done.step) {
            return makeStatusResponse({
                title: 'Underskrevet',
                color: 'green',
            });
        }
        
        return makeStatusResponse({
            title: 'Ikke påbegyndt',
            color: 'grey',
        });
    }

    if (flow.isReset) {
        return makeStatusResponse({
            title: 'Nulstillet',
            color: 'grey',
        });
    }

    if (isAwaitingDocuments(flow)) {
        return makeStatusResponse({
            icon: 'hourglass half',
            color: 'blue',
            title: 'Afventer dokumenter',
            flowDescription: `
                Alle har underskrevet, men vi afventer at modtage de underskrevne dokumenter fra vores underskriftsleverandør.
                Dokumenterne bør være klar meget snart.
            `.trim(),
        });
    }

    if (isCompletionStuck(flow)) {
        return makeStatusResponse({
            icon: 'hourglass half',
            color: 'yellow',
            title: 'Afventer dokumenter',
            flowDescription: `
                Der er et problem hos vores underskriftsleverandør,
                der gør, at vi ikke kan modtage de underskrevne dokumenter endnu.
                Dokumenterne plejer at være klar inden for maks en halv time.
                Hvis problemet bliver ved med at opstå, så kontakt os i supporten.
            `.trim(),
        });
    }

    if (flow.status === 'Active') {
        if (flow.hasProblemsRefreshingStatus) {
            return makeStatusResponse({
                title: 'Ikke opdateret',
                icon: 'warning circle',
                color: 'yellow',
                flowDescription: `
                    Vi har problemer at hente den nyeste status
                    på underskriftsflowet fra vores leverandør.
                    Prøv venligst igen senere.
                    \nSidst opdateret ${new Date(flow.lastUpdated).toLocaleString()}
                `.trim(),
            });
        }

        return makeStatusResponse({
            title: 'Igang',
            icon: 'paper plane',
            color: 'blue',
            flowDescription: 'Underskriftsflowet er igang med at blive underskrevet',
        });
    }

    const statusToReponseMapping = {
        Failed: {
            icon: 'warning circle',
            color: 'red',
            title: 'Fejlet',
            flowDescription: 'Underskriftsflowet er fejlet. Kontakt supporten.',
        },

        Completed: {
            icon: 'check circle',
            color: 'green',
            title: 'Underskrevet',
            flowDescription: 'Underskriftsflowet er underskrevet og færdigt',
            isSigned: true,
        },

        Expired: {
            icon: 'time',
            color: 'yellow',
            title: 'Udløbet',
            flowDescription: 'Underskriftsflowet er udløbet',
        },

        Cancelled: {
            icon: 'warning circle',
            color: 'yellow',
            title: 'Annulleret',
            flowDescription: 'Underskriftsflowet er blevet annulleret',
        },
    };

    if (flow.status in statusToReponseMapping) {
        return makeStatusResponse(statusToReponseMapping[flow.status]);
    }

    return makeStatusResponse({
        icon: 'question circle',
        color: 'grey',
        title: flow.status,
    });
};

export default describeFlowStatus;