import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon, Popup } from 'semantic-ui-react';

const RefetchFlowIcon = ({ refetchFlow, ...iconProps }) => {
    const [working, setWorking] = useState(false);
    const [justUpdated, setJustUpdated] = useState(false);

    useEffect(() => {
        if (!justUpdated) return;

        const timeout = window.setTimeout(() => setJustUpdated(false), 2000);
        return () => window.clearTimeout(timeout);
    }, [justUpdated]);

    const handleRefetch = async () => {
        if (working) return;
        
        setWorking(true);

        try {
            const flow = await refetchFlow({ forceUpdate: true });
            setJustUpdated(true);
            if (!flow.hasProblemsRefreshingStatus) {
                toast.success('Status opdateret');
            }
        } catch {
            toast.error('Kunne ikke opdatere flow...');
        }
        
        setWorking(false);
    };

    return (
        <Popup
            basic
            position='top center'
            content={working ? 'Opdaterer status' : 'Opdatér status'}
            trigger={(
                <Icon
                    onClick={handleRefetch}
                    style={{ marginLeft: '.5em' }}
                    disabled={working || justUpdated}
                    loading={working}
                    name='refresh'
                    link
                    {...iconProps}
                />
            )}
        />
    );
};

export default RefetchFlowIcon;