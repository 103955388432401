import { Link } from 'react-router-dom';
import { Button, Checkbox, Header, Icon, Modal, Popup, Segment } from 'semantic-ui-react';
import FieldGroup from 'design/atoms/FieldGroup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getStatusFromFact } from 'util/FactMapUtil';
import { publish } from 'util/pubsub';
import useUser from 'util/useUser';
import { getFlowSummary } from 'http/esignatur';
import { statusesMap } from 'design/molecules/AppLauncher/userStatuses';
import ColoredText from 'design/atoms/ColoredText';
import Checklist from '../../checklist';
import { downloadIXBRLDocument } from './downloadIXBRL';
import Signflow from '../Signflow';
import describeFlowStatus from '../Signflow/describeFlowStatus';
import SignatureFlowFilesModal from '../Signflow/SignatureFlowFilesModal';
import RefetchFlowIcon from '../Signflow/RefetchFlowIcon';

const STEPS = {
    QUALITY_CONTROL: 'QUALITY_CONTROL',
    APPROVAL: 'APPROVAL',
    SIGNFLOW: 'SIGNFLOW',
};

const toggleStepOpen = (stepID, isOpen) => {
    publish(stepID, isOpen);
};

const ProgressSegment = ({ id, step, title, icon, color, children, disabled, tooltip, loading, progress, error }) => {
    const theTitle = (
        <span>
            <span style={{ marginLeft: '0.25em', width: '1.15em', display: 'inline-block' }}>{step}.</span>
            <span style={{ opacity: 0.80 }}>{title}</span>
        </span>
    );

    return (
        <FieldGroup
            id={id}
            collapsible
            hideChevron
            title={theTitle}
            loading={loading}
            icon={icon}
            iconColor={color}
            tooltip={tooltip}
            progress={progress}
            error={error}
            bodyStyle={{ marginBottom: 0 }}
            tooltipPosition='right center'
        >
            <div
                style={{
                    opacity: disabled ? 0.5 : 1,
                    pointerEvents: disabled ? 'none' : undefined,
                    cursor: disabled ? 'not-allowed' : undefined,
                }}
                children={children}
            />
        </FieldGroup>
    );
};

const Approve = ({ flowContext, updateFlowContext, isLocked, documents }) => {
    const [working, setWorking] = useState(false);
    const user = useUser();
    const hasApprovedDraft = flowContext?.reportApproved;

    let isToggle;
    let helperText;
    let downloadDocumentsButton;
    let checkboxText;

    if (user.impersonation) {
        isToggle = true;

        helperText = (
            <div>
                Læs rapporterne igennem og send dem til din kunder.
            </div>
        );

        downloadDocumentsButton = (
            <div>
                <ColoredText
                    onClick={() => {
                        for (const document of documents) {
                            downloadIXBRLDocument(document, user);
                        }
                    }}
                    content={`Download rapport${documents.length !== 1 ? 'er' : ''}`}
                    icon={<Icon name='download' color='green' />}
                    iconPosition='left'
                    style={{ cursor: 'pointer' }}
                />
            </div>
        );

        checkboxText = 'Fjern udkast';
    } else {
        isToggle = false;

        helperText = (
            <div>
                Læs rapporten igennem og godkend nedenfor.
            </div>
        );

        checkboxText = 'Jeg har læst rapporten';
    }

    return (
        <div style={{ display: 'flex', gap: '0.5em', flexDirection: 'column' }}>
            {helperText}
            {downloadDocumentsButton}
            <div style={{ height: '1px', width: '100%', background: 'lightgray' }}></div>
            <Checkbox
                toggle={isToggle}
                style={{ marginTop: '0.5em' }}
                disabled={working || isLocked}
                loading={working}
                label={checkboxText}
                defaultChecked={hasApprovedDraft}
                onChange={(_, { checked }) => {
                    setWorking(true);
                    updateFlowContext({ reportApproved: checked })
                        .then(() => {
                            if (!hasApprovedDraft) {
                                toggleStepOpen(STEPS.QUALITY_CONTROL, false);
                                toggleStepOpen(STEPS.APPROVAL, false);
                                toggleStepOpen(STEPS.SIGNFLOW, true);
                            }
                        })
                        .finally(() => setWorking(false));
                }}
            />
        </div>
    );
};

const SignatureFlow = ({ reloadProductData, value, documents, runAction, productStatus, docgenID, resetStateToBeforeSignatureFlow }) => {
    const flowID = value?.esignaturSignFlow;
    const [signFlowOpen, setSignFlowOpen] = useState(false);
    const [flow, setFlow] = useState(null);
    const [loadingFlow, setLoadingFlow] = useState(true);

    useEffect(() => {
        if (!flowID) return setLoadingFlow(false);

        getFlowSummary(flowID)
            .then(flow => setFlow(flow))
            .finally(() => setLoadingFlow(false));
    }, [flowID, productStatus]);

    const refetchFlow = useCallback(async (options) => {
        if (!flowID) return;

        const flow = await getFlowSummary(flowID, options?.forceUpdate);

        setFlow(flow);

        return flow;
    }, [flowID]);

    useEffect(() => {
        if (flow?.status === 'Completed' && productStatus.step < statusesMap.done.step) {
            reloadProductData();
        }        
    }, [flow, reloadProductData, productStatus]);

    let statusMessage;
    let color;
    let isSigned = false;

    const buttonProps = {};

    const flowStatus = describeFlowStatus(flow);

    if (loadingFlow) {
        statusMessage = 'Indlæser';
        color = 'grey';
        buttonProps.color = 'grey';
        buttonProps.basic = true;
        buttonProps.disabled = true;
        buttonProps.loading = true;
    } else {
        statusMessage = flowStatus.title;
        color = flowStatus.color;
        isSigned = flowStatus.isSigned;

        if (!flow?.status) {
            color = 'grey';
            buttonProps.content = 'Underskriv';
            buttonProps.color = 'green';
        } else {
            buttonProps.content = 'Åbn';
            buttonProps.basic = true;
            buttonProps.color = color;
        }
    }

    return (
        <div>
            <Popup
                basic
                position='top left'
                disabled={!flowStatus.flowDescription}
                content={flowStatus.flowDescription}
                trigger={(
                    <span>
                        <strong>Status:</strong> <ColoredText bold content={statusMessage} color={color} />
                    </span>
                )}
            />
            {!isSigned && (
                <Button
                    {...buttonProps}
                    style={{ marginTop: '0.5em' }}
                    onClick={() => setSignFlowOpen(true)}
                    size='tiny'
                    icon='signup'
                    fluid
                />
            )}
            {isSigned && (
                <SignatureFlowFilesModal
                    flow={flow}
                    trigger={(
                        <Button
                            style={{ marginTop: '0.5em' }}
                            content='Se dokumenter'
                            size='tiny'
                            icon='copy'
                            primary
                            basic
                            fluid
                        />
                    )}
                />
            )}
            <Modal
                open={signFlowOpen}
                onClose={() => setSignFlowOpen(false)}
            >
                <Modal.Header>
                    <Icon name='signup' /> Underskrift
                    <div style={{ float: 'right', fontSize: 'initial' }}>
                        <Popup
                            basic
                            position='top center'
                            disabled={!flowStatus.flowDescription}
                            content={flowStatus.flowDescription}
                            trigger={(
                                <span>
                                    Status:&nbsp;
                                    <ColoredText
                                        content={flowStatus.title}
                                        color={flowStatus.color}
                                    />
                                </span>
                            )}
                        />
                        
                        {productStatus.step === statusesMap.esignatur_flow.step && (
                            <RefetchFlowIcon
                                refetchFlow={refetchFlow}
                                size='small'
                            />
                        )}
                    </div>
                </Modal.Header>
                <Modal.Content style={{ padding: 0 }}>
                    <Signflow
                        docgenID={docgenID}
                        flow={flow} 
                        refetchFlow={refetchFlow}
                        runAction={runAction}
                        documents={documents}
                        productStatus={productStatus}
                        resetStateToBeforeSignatureFlow={resetStateToBeforeSignatureFlow}
                    />
                </Modal.Content>
            </Modal>
        </div>
    );
};

const StepsSideMenu = ({
    productMetadata,
    yearReportData,
    flowContext,
    updateFlowContext,
    values,
    value,
    hasPayed,
    factsBeingUpdated,
    renderSignflow,
    loading,
    documents,
    isLocked,
    runAction,
    reloadProductData,
    docgenID,
    resetStateToBeforeSignatureFlow,
    paymentURL,
}) => {
    const user = useUser();

    const { checklist, errorCount, warningCount } = useMemo(() => {
        if (!yearReportData) {
            return {};
        }
        
        const yearReportDataProductFact = productMetadata.facts[yearReportData.id];

        let checklistFact;
        for (const actionID of yearReportDataProductFact.supply) {
            const action = productMetadata.actions[actionID];
            if (!action) {
                continue;
            }

            const fact = productMetadata.facts[action.supply];
            if (fact?.dataType !== 'checklist') {
                continue;
            }

            checklistFact = fact;
            break;
        }

        if (!checklistFact) {
            return {};
        }

        const checklist = values[checklistFact.id]?.value?.checklist;

        const errorChecks = [...(checklist?.errors || [])];
        const warningChecks = [...(checklist?.warnings || [])];

        const errorCount = errorChecks.filter(errorCheck => !errorCheck.success).length;
        const warningCount = warningChecks.filter(warningCheck => !warningCheck.success).length;

        return {
            checklist,
            errorChecks,
            warningChecks,
            errorCount,
            warningCount,
        };
    }, [productMetadata, yearReportData, values]);

    const notBoughtMessage = 'Du skal købe fuld adgang til skatteåret for at fortsætte processen';

    const steps = [
        // quality control
        {
            id: STEPS.QUALITY_CONTROL,
            title: 'Kvalitetskontrol',
            component: (
                <Checklist
                    value={checklist}
                    hasPayed={hasPayed}
                    options={{}}
                    values={values}
                    productMetadata={productMetadata}
                    factsBeingUpdated={factsBeingUpdated}
                    onlyShowSummaryItems
                />
            ),
            getStatus() {
                let icon;
                let color;
                let isDone;
                let error;
                let tooltip;

                if (errorCount > 0) {
                    icon = 'warning circle';
                    color = 'red';
                    isDone = false;
                    error = true;
                    tooltip = `Kvalitetskontrollen indeholder ${errorCount} uløst${errorCount !== 1 ? 'e' : ''} fejl`;
                } else if (warningCount > 0) {
                    icon = 'check circle';
                    color = 'yellow';
                    isDone = true;
                    tooltip = `Kvalitetskontrollen er bestået med ${warningCount} ${warningCount === 1 ? 'advarsel' : 'advarsler'}`
                } else {
                    icon = 'check circle';
                    color = 'green';
                    isDone = true;
                    tooltip = 'Kvalitetskontrollen er bestået med 0 fejl og 0 advarsler';
                }

                return {
                    icon,
                    color,
                    isDone,
                    tooltip,
                    error,
                };
            },
            shouldBeDefaultOpen() {
                if (errorCount > 0) {
                    return true;
                }

                if (warningCount > 0 && !flowContext?.reportApproved) {
                    return true;
                }

                if (!hasPayed) {
                    return true;
                }

                return false;
            },
            isLocked: false,
        },
        {
            id: STEPS.APPROVAL,
            title: user.impersonation ? 'Udkast' : 'Gennemlæs rapport',
            prerequisiteText: (
                hasPayed
                    ? 'Kvalitetskontrollen skal være bestået før du kan godkende rapporten'
                    : notBoughtMessage
            ),
            component: (
                <Approve
                    flowContext={flowContext}
                    updateFlowContext={updateFlowContext}
                    isLocked={isLocked}
                    documents={documents}
                />
            ),
            getStatus() {
                const reportApproved = flowContext?.reportApproved;

                return { 
                    isDone: reportApproved,
                    icon: reportApproved ? 'check circle' : undefined,
                    color: reportApproved ? 'green' : undefined,
                };
            },
            shouldBeDefaultOpen() {
                if (this.isLocked) return false;

                if (this.getStatus().isDone) {
                    return false;
                }

                if (errorCount > 0) {
                    return false;
                }

                return true;
            },
            isLocked: !hasPayed,
        },
        {
            id: STEPS.SIGNFLOW,
            title: 'Underskrift',
            prerequisiteText: (
                hasPayed
                    ? 'Du skal godkende rapporten før du kan underskrive den'
                    : notBoughtMessage
            ),
            component: (
                <SignatureFlow
                    documents={documents}
                    value={value}
                    renderSignflow={renderSignflow}
                    runAction={runAction}
                    reloadProductData={reloadProductData}
                    docgenID={docgenID}
                    productStatus={getStatusFromFact(values.STATUS)}
                    resetStateToBeforeSignatureFlow={resetStateToBeforeSignatureFlow}
                />
            ),
            getStatus() {
                const status = getStatusFromFact(values.STATUS);

                const isSigned = status.step >= statusesMap.done.step;

                return {
                    isDone: isSigned,
                    icon: isSigned ? 'check circle' : undefined,
                    color: isSigned ? 'green' : undefined,
                };
            },
            shouldBeDefaultOpen() {
                if (this.isLocked) return false;

                return flowContext?.reportApproved;
            },
            isLocked: !hasPayed,
        },
    ];

    useEffect(() => {
        if (loading) return;

        for (const step of steps) {
            if (step.shouldBeDefaultOpen()) {
                toggleStepOpen(step.id, true);
            }
        }
        // eslint-disable-next-line
    }, [loading], steps); 

    let previousStepIsDone = true;

    const renderedSteps = steps.map((currentStep, idx) => {
        let icon;
        let color;
        let isActive;
        let tooltip;
        let error;

        const stepIsDisabled = currentStep.isLocked || !previousStepIsDone;

        if (loading) {
            color = 'grey';
            icon = 'spinner';
            isActive = false;
        } else if (currentStep.isLocked || !previousStepIsDone) {
            tooltip = currentStep.prerequisiteText;
            icon = <Icon name='lock' color='grey' style={{ opacity: 0.75 }} />
        } else {
            const currentStepStatus = currentStep.getStatus();

            if (!currentStepStatus.isDone) {
                previousStepIsDone = false;
                isActive = true;
            }

            icon = currentStepStatus.icon;
            color = currentStepStatus.color;
            tooltip = currentStepStatus.tooltip;
            error = currentStepStatus.error;
        }

        return (
            <ProgressSegment
                id={currentStep.id}
                key={currentStep.title}
                step={idx + 1}
                title={currentStep.title}
                icon={icon}
                inactive={!isActive}
                disabled={stepIsDisabled}
                color={color}
                children={currentStep.component}
                tooltip={tooltip}
                loading={loading}
                error={error}
            />
        );
    });

    const isRegularUser = !user.isAccountant();

    return (
        <Segment.Group>
            <Segment style={{ padding: '0.75em', background: 'rgb(250, 250, 250)' }}>
                <Header style={{ margin: 0 }} content='Rapport' />
            </Segment>
            {isRegularUser && !hasPayed && (
                <Segment style={{ padding: '.75em' }}>
                    Her ser du et udkast til din årsrapport, hvor balancetallene er låst.
                    Ved køb af produktet låser vi op for tallene og skaber en indberetningsklar årsrapport til dig,
                    som du kan underskrive digitalt.
                    <Link to={paymentURL}>
                        <Button
                            style={{ marginTop: '.5em' }}
                            primary
                            content='Få fuld adgang'
                            fluid
                            size='tiny'
                            icon='unlock'
                            labelPosition='right'
                        />
                    </Link>
                </Segment>
            )}
            {(!isRegularUser || hasPayed) && renderedSteps}
        </Segment.Group>
    );
};

export default StepsSideMenu;