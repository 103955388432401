import { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';

const UnlockModal = ({ trigger, onUnlock }) => {
    const [open, setOpen] = useState(false);
    
    const modal = (
        <Modal open={open} onClose={() => setOpen(false)} size='small'>
            <Modal.Header content='Stop underskriftsflow' />

            <Modal.Content>
                Er du sikker på, at du vil annullere dine underskrifter?
                <br />
                Du vil miste alle nuværende underskrifter
            </Modal.Content>

            <Modal.Actions>
                <Button
                    content='Annuller'
                    color='black'
                    onClick={() => setOpen(false)}
                />
                <Button
                    content='Lås op'
                    primary
                    onClick={() => {
                        setOpen(false);
                        onUnlock();
                    }}
                />
            </Modal.Actions>
        </Modal>
    );

    return (
        <>
            <span
                onClick={() => setOpen(true)}
                children={trigger}
            />
            {modal}
        </>
    );
};

export default UnlockModal;
