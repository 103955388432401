import { Modal, Icon, Button } from 'semantic-ui-react';
import DatePicker from 'design/atoms/DatePicker';
import ColoredText from 'design/atoms/ColoredText';

export const AssemblyDateForm = ({ assemblyDate, onChange, disabled, updating }) => {
    const formattedDate = assemblyDate
        ? new Date(assemblyDate)
        : undefined;

    return (
        <div>
            <p>
                Dato for generalforsamling bliver automatisk sat til
                dags dato. Du har mulighed for at overskrive datoen
                nedenfor.
            </p>
            <p>
                Har du tilføjet en revisorerklæring til rapporten, vil
                dato for rådgivers underskrift også blive overskrevet
                med valgte dato.
            </p>
            <DatePicker
                fluid
                value={formattedDate}
                onChange={date => onChange(date)}
                disabled={disabled}
            />
            {updating && (
                <ColoredText
                    content='Rapporten opdateres'
                    iconPosition='left'
                    icon='spinner'
                    loading
                />
            )}
        </div>
    );
};

const AssemblyDateModal = ({ assemblyDate, onChange, open, onClose, disabled, updating }) => {
    return (
        <Modal open={open} onClose={onClose} size='small'>
            <Modal.Header>
                <Icon name='calendar alternate outline' />
                Dato for generalforsamling
            </Modal.Header>
            <Modal.Content>
                <AssemblyDateForm
                    assemblyDate={assemblyDate}
                    onChange={onChange}
                    disabled={disabled}
                    updating={updating}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' content='Luk' onClick={onClose} />
            </Modal.Actions>
        </Modal>
    );
};

export default AssemblyDateModal;