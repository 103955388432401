import React, { isValidElement, useEffect, useState } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { subscribe, unsubscribe } from 'util/pubsub';

import { Media } from '../Media';
import ColoredText from '../ColoredText';
import FoldableSegment from '../FoldableSegment';

const FieldGroup = ({
    children,
    title,
    topic,
    id,
    onClick,
    collapsible,
    unwrapped,
    completedQuestions,
    totalQuestions,
    icon,
    iconColor,
    progress,
    error,
    hideChevron,
    tooltip,
    tooltipPosition,
    loading,
    rightContent,
    headerStyle = {},
    bodyStyle = {},
    defaultOpen = false
}) => {
    const [open, setOpen] = useState(collapsible ? defaultOpen : true);

    useEffect(() => {
        const subTopics = [];
        id && subTopics.push(id);
        topic && subTopics.push(topic);

        const subscriptions = subTopics.map((topic) => {
            return subscribe(topic, open => {
                setOpen(open);
            });
        });

        return () => {
            subscriptions.forEach(unsubscribe);
        };
    }, [topic, id]);

    let rightContentElement;

    if (rightContent) {
        rightContentElement = rightContent
    } else if (typeof progress === 'number') {
        rightContentElement = totalQuestions && (
            <Media gte='tablet'>
                <ColoredText
                    content={`${completedQuestions} / ${totalQuestions}`}
                />
            </Media>
        );
    } else if (icon) {
        rightContentElement = (
            <span>
                {isValidElement(icon) ? icon : <Icon loading={loading} name={icon} color={iconColor || 'grey'} />}
            </span>
        );
    }

    const isOpen = !collapsible || open;

    const content = (
        unwrapped
            ? (
                <Segment.Group
                    children={children}
                    style={{ border: 'none', boxShadow: 'none'}}
                />
            )
            : children
    );

    return (
        <FoldableSegment
            onClick={onClick}
            title={title}
            progress={progress}
            rightContent={rightContentElement}
            error={error}
            defaultOpen={isOpen}
            collapsible={collapsible}
            unwrapped={unwrapped}
            children={content}
            isOpen={open}
            setIsOpen={setOpen}
            hideChevron={hideChevron}
            tooltip={tooltip}
            tooltipPosition={tooltipPosition}
            headerStyle={headerStyle}
            bodyStyle={bodyStyle}
        />
    );
};

export default FieldGroup;
