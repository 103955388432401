import { Component } from 'react';

class YearReportData extends Component {
    componentDidMount () {
        const { options, values } = this.props;
        const isLocked = values.LOCK?.value?.isLocked || false;
        if (isLocked) return;
        this.props.onChange(options.triggerFact, { time: Date.now() }, { run: true });
    }

    render () {
        return null;
    }
}

export default YearReportData;
