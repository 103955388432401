import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import UploadLogo, { prepareImageFileForLogoCropping } from 'design/molecules/UploadLogo';
import { toast } from 'react-toastify';

const LogoPlaceholder = ({ style = {}, ...props }) => {
    return (
        <div
            children='Vælg logo'
            style={{
                cursor: 'pointer',
                border: '2px dashed black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '150px',
                height: '150px',
                opacity: 0.25,
                ...style,
            }}
            {...props}
        />
    );
};

const NoLogoSelected = ({ onLogoPicked }) => {
    const [image, setImage] = useState(null);
    const hiddenFileuploadRef = useRef();

    const handleFilePicked = async e => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.target?.files || e.dataTransfer?.files || [];

        const selectedFile = files[0];
        if (!selectedFile) {
            return;
        }

        try {
            const image = await prepareImageFileForLogoCropping(selectedFile);
            setImage(image);
        } catch (e) {
            toast.error(e.message);
        }

        e.target.value = '';
    }

    const hiddenFileupload = (
        <input
            ref={hiddenFileuploadRef}
            style={{ display: 'none' }}
            onChange={handleFilePicked}
            type='file'
        />
    );

    const logoPlaceholder = (
        <LogoPlaceholder
            onClick={() => hiddenFileuploadRef.current.click()}
            onDrop={handleFilePicked}
            onDragOver={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        />
    );

    return (
        <>
            <Popup
                basic
                open={!!image}
                onClose={() => setImage(null)}
                closeOnTriggerBlur={true}
                closeOnTriggerMouseLeave={false}
                closeOnDocumentClick={true}
                content={(
                    <UploadLogo
                        hideCurrentLogo
                        defaultImage={image}
                        onCancel={() => setImage(null)}
                        onLogoChanged={() => {
                            onLogoPicked();
                            setImage(null);
                        }}
                    />
                )}
                position='bottom center'
                trigger={logoPlaceholder}
            />
            {hiddenFileupload}
        </>
    );
};

const CurrentLogo = ({ trigger, onLogoPicked, isLocked }) => {
    const [open, setOpen] = useState(false);
    const [startScrollY, setStartScrollY] = useState(null);

    useEffect(() => {
        if (!open) return;

        if (startScrollY === null) {
            setStartScrollY(window.scrollY);
            return
        }

        // close popup on scroll
        const onScrollChange = () => {
            const scrollDelta = Math.abs(window.scrollY - startScrollY);
            if (scrollDelta >= 750) {
                setOpen(false);
            }
        };

        window.addEventListener('scroll', onScrollChange);
        return () => window.removeEventListener('scroll', onScrollChange);
    }, [startScrollY, open]);

    return (
        <Popup
            basic
            disabled={isLocked}
            onClose={() => setOpen(false)}
            closeOnTriggerBlur={true}
            closeOnTriggerMouseLeave={false}
            closeOnDocumentClick={true}
            position='bottom center'
            trigger={
                <div onClick={() => setOpen(true)}>{trigger}</div>
            }
            open={open}
            content={
                <div
                    style={{ width: '500px' }}
                    onBlur={() => setOpen(false)}
                >
                    <UploadLogo
                        hideCurrentLogo
                        promptFileOnMount
                        onLogoChanged={() => {
                            onLogoPicked();
                            setOpen(false);
                        }}
                    />
                </div>
            }
        />
    );

};

const InlineLogoPicker = ({ trigger, onLogoPicked, isLocked, isEmpty }) => {
    if (!isEmpty) {
        return (
            <CurrentLogo
                trigger={trigger}
                onLogoPicked={onLogoPicked}
                isLocked={isLocked}
            />
        );
    }

    if (isLocked) {
        return (
            <LogoPlaceholder
                style={{ opacity: 0, pointerEvents: 'none' }}
            />
        );
    }

    return (
        <NoLogoSelected
            onLogoPicked={onLogoPicked}
        />
    );
};

export default InlineLogoPicker;