import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import cn from 'classnames';
import {
    Button,
    Checkbox,
    Popup,
    Segment,
    Grid,
    Modal,
    Icon,
    Flag,
    List,
} from 'semantic-ui-react';
import countries from 'lang/countries';
import { Media } from 'design/atoms/Media';
import PDFView from 'design/atoms/PDFView';
import SegmentMenuItem from 'design/atoms/SegmentMenuItem';
import ColoredText from 'design/atoms/ColoredText';
import ReportEditor from 'design/molecules/ReportEditor';
import UploadLogo from 'design/molecules/UploadLogo';
import { getFileData } from 'http/file-storage';
import { isAccountant, isAdmin, isAkademi, isSelskab } from 'util/userMethods';
import { formatDateTime } from 'util/format/DateTime';
import withUserData from 'util/withUserData';
import downloadBlob, { downloadBase64 } from 'util/downloadBlob';
import styles from './PDFViewer.module.scss';
import deepCopyObject from 'util/deepCopyObject';
import AssemblyDateModal from 'design/pages/Product/FieldTypes/FactWidgets/documentGenerator/AssemblyDateModal';

function SimpleLink({
    content,
    icon,
    disabled,
    onClick,
    link = true,
    iconPosition = 'left',
}) {
    return (
        <ColoredText
            style={{ paddingRight: '8px', paddingLeft: '8px' }}
            content={content}
            icon={icon}
            disabled={disabled}
            onClick={onClick}
            iconPosition={iconPosition}
            underlined={false}
            link={link}
        />
    );
}

const PDFViewer = (props) => {
    const [assemblyModal, setAssemblyModal] = useState(false);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [preselectedFile, setPreselectedFile] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);
    const [editorMode, setEditorMode] = useState(false);
    const [documentOverrideConfiguration, setDocumentOverrideConfiguration] = useState({});    
    const [activeDocumentIndex, setActiveDocumentIndex] = useState(0);

    const flowContext = props.flowContext || {};
    const { reportTemplateID, reportTemplateVersion } = props.yearReportData || {};

    const availableDocuments = [
        {
            title: 'Årsrapport',
            icon: 'file pdf',
            isInternal: !props.showInternalPDF,
            error: null,
            uploadLogoOverlay: true,
            pdfID: props.pdfId,
            reportTemplateID,
            reportTemplateVersion,
            documentComponentsFileID: props.primaryPdfDocumentComponentsFileID,
            overrideConfiguration: flowContext.overrideConfiguration,
            setOverrideConfiguration: (flowContext, overrideConfiguration) => flowContext.overrideConfiguration = overrideConfiguration,
        },
    ];
    if (props.showInternalPDF) {
        availableDocuments.push({
            title: 'Internt regnskab',
            icon: 'file pdf outline',
            isInternal: true,
            uploadLogoOverlay: true,
            pdfID: props.internalPdfId,
            reportTemplateID,
            reportTemplateVersion,
            documentComponentsFileID: props.internalPdfDocumentComponentsFileID,
            overrideConfiguration: flowContext.internalReportOverrideConfiguration,
            setOverrideConfiguration: (flowContext, overrideConfiguration) => flowContext.internalReportOverrideConfiguration = overrideConfiguration,
        });
    }

    if (props.showSecondaryDocuments) {
        for (const secondaryDocument of (props.secondaryDocuments || [])) {
            const { pdfFileID, pdfDocumentComponentsFileID, documentBase64, documentMetadata, error } = secondaryDocument;
            const title = documentMetadata?.title;
            const reportTemplateID = documentMetadata?.reportTemplateID;
            const templateVersion = documentMetadata?.templateVersion;
            const overrideConfiguration = (flowContext.secondaryDocumentsOverrideConfiguration || {})[reportTemplateID];
            const pdfOptions = {};
            if (pdfFileID) {
                pdfOptions.pdfID = pdfFileID;
            } else {
                // legacy solution - pdf bytes stored in the doc-gen fact directly
                pdfOptions.pdfBase64 = `data:application/pdf;base64,${documentBase64}`;
            }
            
            availableDocuments.push({
                title,
                error,
                icon: 'file alternate outline',
                uploadLogoOverlay: false,
                reportTemplateID,
                reportTemplateVersion: templateVersion,
                documentComponentsFileID: pdfDocumentComponentsFileID,
                overrideConfiguration,
                ...pdfOptions,
                setOverrideConfiguration: (flowContext, overrideConfiguration) => {
                    if (!flowContext.secondaryDocumentsOverrideConfiguration) {
                        flowContext.secondaryDocumentsOverrideConfiguration = {};
                    }
                    flowContext.secondaryDocumentsOverrideConfiguration[reportTemplateID] = overrideConfiguration;
                },
            });
        }
    }

    const activeDocument = availableDocuments[activeDocumentIndex] || availableDocuments[0];

    const onClickDownload = async () => {
        const fileNameBase = activeDocument.title;
        const fileName = `${fileNameBase} ${props.taxYear} ${props.userData.companyName.replace(/\./g, '_')}`;
        
        if (activeDocument.pdfBase64) {
            const parseResult = [...activeDocument.pdfBase64.matchAll(new RegExp('data:([a-z/-]+);base64,(.+)', 'g'))][0];
            const [contentType, base64String] = [1, 2].map(i => parseResult[i]);
            downloadBase64(contentType, base64String, fileName);
            return;
        }

        const { data } = await getFileData(activeDocument.pdfID);
        const asBlob = new Blob([new Uint8Array(data.data)], {
            type: 'application/pdf',
        });
        downloadBlob(asBlob, fileName);
    };

    const onClickUploadLogo = file => {
        setUploadModalVisible(true);
        setPreselectedFile(file);
        setShowOptions(false);
    };

    const onModalClose = () => {
        setUploadModalVisible(false);
        setPreselectedFile(null);
    };

    const onLogoChanged = () => {
        props.regeneratePDFDocument();
        onModalClose();
    };

    const renderTimestamp = date => {
        return <span>
            Opdateret d. {formatDateTime(date)}
        </span>;
    };

    const renderDownloadButton = () => {
        if (!props.downloadDisabled) {
            return (
                <SimpleLink
                    icon='download'
                    content='Download PDF'
                    onClick={onClickDownload}
                />
            );
        }

        const hoverMessage = props.downloadDisabledMessage || 'Download af PDF deaktiveret';
        const trigger = <span>
            <SimpleLink
                disabled
                icon='download'
                content='Download PDF'
            />
        </span>;
        return <Popup
            position='right center'
            trigger={trigger}
            content={hoverMessage}
        />;
    };

    const renderEditReportButton = () => {
        if (!(isAccountant(props.userData) || isAdmin(props.userData))) {
            return null;
        }

        if (!activeDocument.documentComponentsFileID) {
            return null;
        }

        return (
            <Grid.Row className={styles.optionsRow}>
                <SimpleLink
                    content={`Redigér ${activeDocument.title.toLowerCase()}`}
                    icon='pencil'
                    onClick={() => {
                        setDocumentOverrideConfiguration(deepCopyObject(activeDocument.overrideConfiguration || {}));
                        setEditorMode(true);
                        setShowOptions(false);
                    }}
                />
            </Grid.Row>
        );
    };
    
    const renderOptionsButton = () => {
        const renderPopupContent = () => {
            return (
                <Grid divided columns='equal'>
                    {renderChangeLanguageButton()}
                    {renderForceWatermarkCheckbox()}
                    {renderUploadLogoButton()}
                    {renderChangeSignDateButton()}
                    {renderEditReportButton()}
                    {renderDownloadXbrlButton()}
                    {renderSetToSignedButton()}
                </Grid>
            );
        };

        return <Popup
            trigger={<SimpleLink icon='cogs' content='Valgmuligheder' disabled={props.isLocked}/>}
            on='click'
            content={renderPopupContent()}
            disabled={props.isLocked}
            open={showOptions}
            onOpen={() => setShowOptions(true)}
            onClose={() => {
                if(!showLanguages) {
                    setShowOptions(false)
                }
            }}
        />
    };

    const renderChangeLanguageButton = () => {
        const { isFlowContextNodePresent, flowContext, updateFlowContext } = props;
        if(!isFlowContextNodePresent) {
            return null;
        }

        if (!flowContext) {
            return null;
        }

        const trigger = (
            <Grid.Row className={cn(styles.optionsRow, styles.optionsLanguageParent)}>
                <SimpleLink
                    icon='flag'
                    content='Sprog'
                    link={false}
                />
                <Icon name='right triangle' textAlign='right'></Icon>
            </Grid.Row>
        );

        return (
            <Popup
                basic
                position='right center'
                on={'hover'}
                hoverable
                trigger={trigger}
                content={
                    <List relaxed>
                    {
                        countries.map(country => (
                            <List.Item
                                onClick={() => {
                                    updateFlowContext({
                                        languageContext: {
                                            ...flowContext.languageContext,
                                            primaryLanguage: country.language.code,
                                        },
                                    })
                                }} className={styles.optionsLanguageChild}>
                                <Flag name={country.flagCode} />
                                <span>
                                    {country.language.name}
                                    {(props.flowContext.languageContext.primaryLanguage === country.language.code) && <>&nbsp;<Icon name='checkmark' color='green'/></>}
                                </span>
                            </List.Item>
                        ))
                    } 
                    </List>
                }
                offset={[16, -8]}
                style={{ padding: 0 }}
                open={showLanguages}
                onOpen={() => setShowLanguages(true)}
                onClose={() => setShowLanguages(false)}
            />
        );
    };

    const renderForceWatermarkCheckbox = () => {
        if (!isAccountant(props.userData)) {
            return null;
        }

        if (isAkademi(props.userData)) {
            return null;
        }

        const { flowContext, updateFlowContext } = props;
        if (!flowContext) {
            return null;
        }

        return <Grid.Row className={cn(styles.optionsRow, styles.optionsLanguageParent)}>
            <SimpleLink
                icon='tint'
                content='Vis vandmærke?'
                link={false}
            />
            <div style={{ paddingRight: '5px' }}>
                <Checkbox
                    checked={flowContext.forceWatermark}
                    onChange={(_, { checked }) => {
                        updateFlowContext({ forceWatermark: checked });
                    }}
                />
            </div>
        </Grid.Row>
    };
    const renderUploadLogoButton = () => {
        const { logo } = props.userData;
        let btnText = 'Upload logo';
        if (logo) {
            btnText = 'Ændr logo';
        }
        return <Grid.Row className={styles.optionsRow}>
            <SimpleLink
                icon='upload cloud'
                content={btnText}
                onClick={onClickUploadLogo}
                disabled={props.isLocked}
            />
        </Grid.Row>;
    };

    const renderChangeSignDateButton = () => {
        if (!isAccountant(props.userData)) {
            return null;
        }

        if (!props.canOverwriteAssemblyDate) {
            return null;
        }

        return (
            <Grid.Row className={styles.optionsRow}>
                <SimpleLink
                    icon='calendar alternate outline'
                    content='Dato for generalforsamling'
                    onClick={() => {
                        setAssemblyModal(true);
                        setShowOptions(false);
                    }}
                />
            </Grid.Row>
        );
    };

    const renderDownloadXbrlButton = () => {
        if (!isAdmin(props.userData)) {
            return null;
        }

        if (!isSelskab(props.userData)) {
            return null;
        }

        return (
            <Grid.Row className={styles.optionsRow}>
                <SimpleLink
                    icon='download'
                    content='Download XBRL-filer'
                    onClick={props.downloadXBRLFiles}
                />
            </Grid.Row>
        );
    };

    const renderSetToSignedButton = () => {
        if (!isAdmin(props.userData)) {
            return null;
        }

        if (!props.canReportBeSetAsSigned()) {
            return null;
        }
        
        return (
            <Grid.Row className={styles.optionsRow}>
                <SimpleLink
                    icon='check'
                    content='Sæt som underskrevet'
                    onClick={props.setReportAsSigned}
                />
            </Grid.Row>
        );
    };

    const renderPDFOptions = () => {
        return (
            <Grid columns={2} stackable verticalAlign='middle'>
                <Media lt='computer'>
                    <Grid.Row textAlign='center'>
                        <Grid.Column>
                            {renderDownloadButton()}
                        </Grid.Column>
                        <Grid.Column>
                            {renderTimestamp(props.date)}
                        </Grid.Column>
                    </Grid.Row>
                </Media>
                <Media gte='computer'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            {renderDownloadButton()}
                            {renderOptionsButton()}
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='right'>
                            {renderTimestamp(props.date)}
                        </Grid.Column>
                    </Grid.Row>
                </Media>
            </Grid>
        );
    };

    const renderPDFMenu = () => {
        const menuItems = availableDocuments.map((document, docIdx) => {
            return (
                <SegmentMenuItem
                    active={activeDocumentIndex === docIdx}
                    onClick={() => {
                        if (document.error) {
                            toast.error('Dokumentet kunne ikke genereres - kontakt supporten');
                        } else {
                            setActiveDocumentIndex(docIdx)
                        }
                    }}
                    content={document.title}
                    error={document.error}
                /> 
            );
        });

        // no need to render a menu, if theres only one menu item or less
        if (menuItems.length <= 1) {
            return null;
        }

        return <Segment.Group horizontal>{menuItems}</Segment.Group>;
    };

    const renderChangeLogoModal = () => {
        return (
            <Modal
                open={uploadModalVisible}
                onClose={onModalClose}
                className={cn(styles.noselect, styles.modal)}
                closeIcon
            >
                <Modal.Header className={styles.modalHeader}>
                    Logo
                </Modal.Header>
                <Modal.Content>
                    <UploadLogo
                        preselectedFile={preselectedFile}
                        onLogoChanged={onLogoChanged}
                        hideToast
                    />
                </Modal.Content>
            </Modal>
        );
    };

    const renderReportEditorModal = () => {
        const { reportTemplateVariables, logoID } = props.yearReportData;

        // TODO: use same language code identifiers across whole system...
        const convertedLanguageCode = {
            'da-DK': 'dk',
            'en-GB': 'gb',
        }[flowContext?.languageContext?.primaryLanguage || 'da-DK'];

        return (
            <Modal open={editorMode}>
                <Modal.Header>
                    <Icon name='pencil' /> Redigér {activeDocument.title.toLowerCase()}
                    <Button
                        basic
                        primary
                        floated='right'
                        icon='save'
                        content='Gem ændringer'
                        onClick={() => {
                            props.updateFlowContext(flowContext => {
                                activeDocument.setOverrideConfiguration(flowContext, documentOverrideConfiguration);
                            });
                            setEditorMode(false);
                        }}
                        style={{ marginTop: '-5px' }}
                    />
                    <Button
                        basic
                        floated='right'
                        color='black'
                        icon='x'
                        content='Luk uden at gemme'
                        onClick={() => setEditorMode(false)}
                        style={{ marginTop: '-5px' }}
                    />
                </Modal.Header>
                <Modal.Content>
                    <ReportEditor
                        documentOverrideConfiguration={documentOverrideConfiguration}
                        setDocumentOverrideConfiguration={setDocumentOverrideConfiguration}
                        templateID={activeDocument.reportTemplateID}
                        templateVersion={activeDocument.reportTemplateVersion}
                        templateVariables={reportTemplateVariables}
                        logoID={logoID}
                        productID={props.productID}
                        productLabel={props.productLabel}
                        yearReportDataNodeID={props.yearReportDataNodeID}
                        isInternal={activeDocument.isInternal}
                        language={convertedLanguageCode}
                        isFirstYear={props.isFirstYear}
                    />
                </Modal.Content>
            </Modal>
        );
    };

    const renderSignDateOverwriteModal = () => {
        return (
            <AssemblyDateModal
                open={assemblyModal}
                onClose={() => setAssemblyModal(false)}
                assemblyDate={props.assemblyDate}
                onChange={date => props.setAssemblyDate(date)}
                disabled={props.isLocked || props.updating}
                updating={props.updating}
            />
        );
    };

    const renderActiveDocument = () => {
        console.log( activeDocument.error);
        if (activeDocument.error) {
            return <Segment>
                <Icon name='warning sign' />
                Dokumentet kunne ikke genereres:
                <br />
                {activeDocument.error}
            </Segment>;
        }

        return (
            <PDFView
                pdfID={activeDocument.pdfID || activeDocument.pdfBase64}
                pdfHash={props.pdfHash}
                onUploadLogo={onClickUploadLogo}
                parentUpdating={props.updating}
                uploadLogo={!props.isLocked && activeDocument.uploadLogoOverlay}
            />
        );
    };

    return (
        <>
            {renderPDFMenu()}
            <Segment placeholder>
                {renderActiveDocument()}
            </Segment>
            <Segment>{renderPDFOptions()}</Segment>
            {renderChangeLogoModal()}
            {renderSignDateOverwriteModal()}
            {renderReportEditorModal()}
        </>
    );
};

PDFViewer.propTypes = {
    pdfId: PropTypes.string.isRequired,
    updating: PropTypes.bool,
    isLocked: PropTypes.bool,
    regeneratePDFDocument: PropTypes.func.isRequired,
    downloadDisabled: PropTypes.bool,
    downloadDisabledMessage: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default compose(withUserData, withRouter)(PDFViewer);
