import { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Input, Loader, Modal, Popup, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import reportEditor from 'http/reportEditor';
import UploadLogo from 'design/molecules/UploadLogo';
import ColoredText from 'design/atoms/ColoredText';
import downloadIXBRL from './downloadIXBRL';

const ReportThemeSettingsModal = ({ onClose, onSave, isLocked }) => {
    const [theme, setTheme] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        reportEditor.getUserDefinedTheme()
            .then(theme => setTheme(theme || {}))
            .finally(() => setLoading(false));
    }, []);

    const handleClose = async () => {
        await reportEditor.putUserDefinedTheme(theme);
        onSave();
        onClose();
    };

    const updateTheme = (updator = {}) => {
        setTheme({
            ...theme,
            ...updator,
        });
    };

    const updateThemeColor = (colorKey, colorValue) => {
        const currentColors = theme.colors || {};
        updateTheme({
            colors: {
                ...currentColors,
                [colorKey]: colorValue,
            },
        });
    };

    const validateHexColor = (hexColor) => {
        if (!hexColor) return true;
        return /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexColor);
    };

    return (
        <Modal open onClose={handleClose} closeIcon>
            <Modal.Header>
                <Icon name='cog' />
                Rapportindstillinger
            </Modal.Header>
            <Modal.Content style={{ cursor: isLocked ? 'not-allowed' : undefined }}>
                {loading && <Loader inline='centered' active />}
                {!loading && (
                    <Form style={{ opacity: isLocked ? 0.75 : 1, pointerEvents: isLocked ? 'none' : undefined }}>
                        <Form.Group widths='equal'>
                            <Form.Field error={!validateHexColor(theme.colors?.primary)}>
                                <label>Primær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('primary', value)}
                                    defaultValue={theme.colors?.primary}
                                    error={!validateHexColor(theme.colors?.primary)}
                                    placeholder='Primær mærkefarve...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.secondary)}>
                                <label>Sekundær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('secondary', value)}
                                    defaultValue={theme.colors?.secondary}
                                    placeholder='Sekundær mærkefarve...'
                                />
                            </Form.Field>
                            <Form.Field error={!validateHexColor(theme.colors?.tertiary)}>
                                <label>Tertiær mærkefarve</label>
                                <Input
                                    onChange={(_, { value }) => updateThemeColor('tertiary', value)}
                                    defaultValue={theme.colors?.tertiary}
                                    placeholder='Tertiær mærkefarve...'
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Font</label>
                            <Dropdown
                                selection
                                clearable
                                placeholder='Overwrite the default font...'
                                defaultValue={theme?.fontAssetID}
                                onChange={(_, { value }) => updateTheme({ fontAssetID: value })}
                                // matches fonts defined in the report-editor service at:
                                // src/routes/assets/static/'
                                options={[
                                    { text: 'Arial', value: 'font_arial.ttf' },
                                    { text: 'Open Sans', value: 'font_open_sans.ttf' },
                                    { text: 'Roboto', value: 'font_roboto.ttf' },
                                    { text: 'Segoe UI', value: 'font_segoe_ui.ttf' },
                                    { text: 'Tinos', value: 'font_tinos.ttf' },
                                ]}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Logo</label>
                            <UploadLogo />
                        </Form.Field>
                    </Form>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='black'
                    content='Luk'
                    onClick={handleClose}
                />
            </Modal.Actions>
        </Modal>
    );
};

const ReportOptionButton = ({ onClick, content, icon, tooltip, ...props }) => {
    const button = (
        <div
            style={{
                border: '1px solid rgb(190, 190, 190)',
                borderRadius: '0.5em',
                padding: '0.25em 1em',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <ColoredText
                iconPosition='left'
                content={content}
                icon={icon}
                underlined={false}
                link
                {...props}
            />
        </div>
    );

    return (
        <Popup
            disabled={!tooltip}
            trigger={button}
            content={tooltip}
            position='left center'
        />
    );
};

const FinishReportButton = ({ onConfirm }) => {
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    return (
        <>
            <ReportOptionButton
                content='Færdiggør rapport'
                icon={<Icon name='check circle' color='green' />}
                onClick={() => setConfirmationModalOpen(true)}
                tooltip='Når din rapport er færdig kan du låse den her'
            />
            <Modal open={confirmationModalOpen} onClose={() => setConfirmationModalOpen(false)}>
                <Modal.Header>
                    Færdiggør rapport
                </Modal.Header>
                <Modal.Content>
                    Er du sikker på, at du vil færdiggøre din rapport?
                    Når du afslutter rapporten, kan du ikke længere redigere den eller ændre dine svar.
                    Du bør kun gøre dette, hvis du har afsluttet alle årets opgaver og er klar til at påbegynde arbejdet med næste års rapport.
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color='black'
                        content='Annullér'
                        onClick={() => setConfirmationModalOpen(false)}
                    />
                    <Button
                        primary
                        content='Færdiggør'
                        onClick={onConfirm}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

const IXBRLEsgReportViewer = ({ loading, error, productIsLocked, contentIsReady, updatedAtTimestamp, value, onChange, taxReportedProductFact, hasPayed, documentWrapper, triggerReportRegeneration }) => {
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownloadIXBRL = async () => {
        setIsDownloading(true);

        try {
            await downloadIXBRL(value.rawIXBRL);
        } catch {
            toast.error('Kunne ikke downloade rapporten...');
        } finally {
            setIsDownloading(false);
        }
    };

    let color;
    let icon;
    let text;
    if (loading) {
        color = 'grey';
        icon = 'spinner';
        text = 'Din rapport indlæses...';
    } else if (error) {
        color = 'red';
        icon = 'warning sign';
        text = 'Der opstod en fejl og din rapport kunne ikke indlæses...';
    } else {
        color = 'green';
        icon = 'check circle';
        text = 'Din rapport er ' + (productIsLocked ? 'færdig!' : 'klar');
    }

    return (
        <>
            <Segment color={color}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <Header>
                            <Icon
                                name={icon}
                                color={color}
                                loading={loading}
                            />
                            <Header.Content>
                                {text}
                                {contentIsReady && (
                                    <Header.Subheader>
                                        <b>Sidst opdateret:</b> {updatedAtTimestamp}
                                    </Header.Subheader>
                                )}
                            </Header.Content>
                        </Header>
                    </div>
                    {contentIsReady && (
                        <div style={{ display: 'flex', justifyContent: 'right', flexDirection: 'column', gap: '0.25em' }}>
                            <ReportOptionButton
                                content='Rapportindstillinger'
                                icon='cog'
                                onClick={() => setSettingsModalOpen(true)}
                                tooltip='Vælg logo, firmafarver og skrifttype'
                            />

                            <ReportOptionButton
                                onClick={() => {
                                    handleDownloadIXBRL()
                                        .catch(() => toast.error('Dokumentet kunne ikke downloades...'))
                                        .finally(() => setIsDownloading(false));
                                }}
                                content='Download som iXBRL'
                                icon={<Icon name={isDownloading ? 'spinner' : 'download'} loading={isDownloading} />}
                                disabled={isDownloading}
                                tooltip='Hent din rapport som en iXBRL-fil'
                            />

                            {taxReportedProductFact && !productIsLocked && hasPayed && (
                                <FinishReportButton
                                    onConfirm={() => {
                                        onChange(taxReportedProductFact.id, { boolean: true });
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Segment>
            {documentWrapper}
            {settingsModalOpen && (
                <ReportThemeSettingsModal
                    isLocked={productIsLocked}
                    onClose={() => setSettingsModalOpen(false)}
                    onSave={triggerReportRegeneration}
                />
            )}
        </>
    );
};

export default IXBRLEsgReportViewer;