import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import set from 'lodash.set';
import { Segment, Icon, Header, Loader } from 'semantic-ui-react';
import { runActionRPC } from 'http/productEngine';
import { downloadText } from 'util/downloadBlob';
import { isAdmin, isAccountant, isAkademi } from 'util/userMethods';
import { STATUS_FACT_ID } from 'util/FactMapUtil';
import withUserData from 'util/withUserData';
import ColoredText from 'design/atoms/ColoredText';
import PDFViewer from 'design/molecules/PDFViewer';
import { DocGenOptionName, DocGenVariantDownloadLink, DocGenVariantIXBRL, DocGenVariantPDF, DocGenVariantSignflow, TypeFlowContext } from 'design/pages/Product/FieldTypes/Fact';
import { statusesMap, userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import FieldGroup from '../../../components/FieldGroup';
import IXBRLViewer from './IXBRLViewer';
import DocgenDownloadLink from './DocgenDownloadLink';
import EsignaturSegment from './Signflow/EsignaturSegment';

class DocumentGenerator extends React.Component {
    static propTypes = {
        isLocked: PropTypes.bool,
        value: PropTypes.shape({
            pdf: PropTypes.string.isRequired,
            filename: PropTypes.string,
            signFlow: PropTypes.object,
        }),
    };

    hasPDF = () => !!this.getRelevantPDF(this.props.value);

    getRelevantPDF = value => value?.signedPdf || value?.pdf;

    getRelevantInternalPDF = value => value?.signedInternalPdf || value?.internalPdf;

    getHash = value => value?.inputHash;

    userIsAdminOrAccountant = () => {
        const { userData } = this.props;

        return isAccountant(userData) || isAdmin(userData);
    };

    hasInternalPDF = () => {
        if (!!this.getRelevantInternalPDF(this.props.value)) {
            return true;
        }

        if (!!this.props.value.internalReportRawIXBRL) {
            return true;
        }

        return false;
    };

    showInternalPDF = () => {
        if (!this.hasInternalPDF()) return false;
        if (!this.userIsAdminOrAccountant()) return false;
        return true;
    };

    onSendToSigning = async ({ signees, notificationMedia, includeInternalReport, attachedSections, attachedDocuments, attachedSecondaryDocumentIndices }) => {
        // run doc-gen action w/ "startSignatureFlow" flag
        return await this.props.runAction({
            startSignatureFlow: true,
            flowOptions: {
                signees,
                notificationMedia,
                includeInternalReport,
                attachedSections,
                attachedDocuments,
                attachedSecondaryDocumentIndices,
            },
        });
    };

    getAssociatedFlowContextProductFact = () => {
        const { productMetadata, id } = this.props;

        const documentGeneratorProductFact = productMetadata.facts[id];
        const documentGeneratorAction = productMetadata.actions[documentGeneratorProductFact.supplier];

        const supplierFlowContextID = documentGeneratorAction.requiresFacts.find(factID => {
            const fact = productMetadata.facts[factID];
            return fact.dataType === TypeFlowContext;
        });

        return productMetadata.facts[supplierFlowContextID];
    };

    getFlowContextFact = () => {
        const flowContextFact = this.getAssociatedFlowContextProductFact();
        if (!flowContextFact) {
            return null;
        }

        return this.props.values[flowContextFact.id];
    };

    isFlowContextNodePresent = () => {
        return !!this.getAssociatedFlowContextProductFact();
    };

    updateFlowContext = (overwrite) => {
        if (!overwrite) {
            return;
        }

        const { onChange } = this.props;
        const flowContextNode = this.getFlowContextFact();
        if(!flowContextNode) {
            return;
        }

        const updatedFlowContext = { ...(flowContextNode.value || {}) };
        if (overwrite instanceof Function) {
            overwrite(updatedFlowContext);
        } else {
            for (const [propertyPath, value] of Object.entries(overwrite)) {
                set(updatedFlowContext, propertyPath, value);
            }
        }

        return onChange(flowContextNode.id, updatedFlowContext, { run: true });
    };

    getAssemblyDateFactID = () => {
        return this.props.options?.assemblyDateOverwriteFactID;
    };

    canOverwriteAssemblyDate = () => {
        return !!this.getAssemblyDateFactID();
    };

    getAssemblyDate = () => {
        const { values } = this.props;
        const dateLookup = values[this.getAssemblyDateFactID()];
        return dateLookup?.value?.date;
    };

    getYearReportData = () => {
        const { values, options, productMetadata } = this.props;
        const { facts, actions } = productMetadata;
        const { yearReportTriggerFact } = options;
        
        try {
            const triggerFact = facts[yearReportTriggerFact];
            const [triggerFactSupply] = triggerFact.supply;
            const yearReportDataAction = actions[triggerFactSupply];
            const yearReportDataFact = facts[yearReportDataAction.supply];
            return {
                id: yearReportDataFact.id,
                data: values[yearReportDataFact.id].value.yearReportData,
            };
        } catch {
            return null;
        }
    };

    downloadXBRLFiles = async () => {
        const { productMetadata, id, productId, productLabel, userData } = this.props;

        const productFact = productMetadata.facts[id];
        const xbrlActionID = productFact.supply.find(supplyID => {
            const action = productMetadata.actions[supplyID];
            if (!action) {
                return false;
            }

            return action.func === 'XBRLReport';
        });

        if (!xbrlActionID) {
            toast.error('XBRL-dokumenter kunne ikke genereres');
            return;
        }

        const xbrlFiles = await runActionRPC(productId, productLabel, xbrlActionID, 'generateXBRLDocuments');

        const fileFriendlyUserName = [...userData.displayName].filter(char => {
            const lowerCase = char.toLowerCase();
            const isChar = lowerCase >= 'a' && lowerCase <= 'z';
            const isDigit = lowerCase >= '0' && lowerCase <= '9';
            const isDanishVowel = ['æ', 'ø', 'å'].includes(lowerCase);
            return isChar || isDigit || isDanishVowel;
        }).join('');

        downloadText(`${fileFriendlyUserName}_xbrl.xml`, 'application/xml', xbrlFiles.xbrl);

        if (xbrlFiles.supplementaryXbrl) {
            downloadText(`${fileFriendlyUserName}_supplerende_xbrl.xml`, 'application/xml', xbrlFiles.supplementaryXbrl);
        }
    };

    canReportBeSetAsSigned = () => {
        const { values } = this.props;

        const statusValue = values?.STATUS?.value?.string;
        const status = statusesMap[statusValue];
        if (!status) {
            return false;
        }

        const canSign = status.step < statusesMap.esignatur_flow.step;
        if (!canSign) {
            return false;
        }

        return true;
    };

    setReportAsSigned = async () => {
        if (!this.canReportBeSetAsSigned()) {
            return;
        }

        await this.props.onChange(
            STATUS_FACT_ID,
            { string: userStatusesEnum.DONE },
            { shouldArtificiallySetSignFlowAsDone: true},
        );
    };

    setAssemblyDate = async date => {
        const { onChange } = this.props;
        const factID = this.getAssemblyDateFactID();
        if (!factID) {
            return;
        }

        const isodate = (
            new Date(date + (1000 * 60 * 60 * 2))
            .toISOString()
            .substring(0, 10)
        );
        
        // update assembly date overwite fact...
        await onChange(factID, {
            date: isodate,
        });

        // ...and trigger PDF regeneration
        this.regenerateDocument();
    };

    regenerateDocument = (updateContext = {}) => {
        const { onChange, options } = this.props;
        if (options && options.yearReportTriggerFact) {
            const timeOfUpdate = { number: Date.now() };
            return onChange(options.yearReportTriggerFact, timeOfUpdate, { run: true, ...updateContext });
        }
    };

    renderPaymentReminder = () => {
        const { hasPayed, paymentURL, userData } = this.props;

        // not relevant if admin
        if (isAdmin(userData)) {
            return null;
        }

        // not relevant if paid
        if (hasPayed) {
            return null;
        }

        return (
            <Segment
                content={
                    <p>
                        Nedenfor ser du et udkast til din årsrapport, hvor balancetallene er låst.
                        Ved køb af produktet låser vi op for tallene og skaber en indberetningsklar årsrapport til dig,
                        som du kan underskrive digitalt.
                        <br />
                        <ColoredText
                            link
                            icon='unlock'
                            iconPosition='left'
                            color='green'
                            content='Lås op for årsrapport nu'
                            onClick={() => this.props.history.push(paymentURL)}
                        />
                    </p>
                }
            />
        );
    };

    renderContent = () => {
        const { value, isLocked, hasPayed, updating, userData: user } = this.props;

        if (updating && !this.hasPDF()) {
            return (
                <Segment textAlign='center'>
                    <Loader inline active size='huge' />
                    <br />
                    Genererer PDF...
                </Segment>
            );
        }

        if (!this.hasPDF()) {
            return (
                <Segment textAlign='center'>
                    <Icon
                        color='grey'
                        name='file pdf outline'
                        size='huge'
                    />
                    <Header
                        color='grey'
                        content='Intet dokument'
                    />
                    Der mangler noget data før årsrapporten kan dannes. Har du udfyldt alle felter?
                </Segment>
            );
        }

        const yearReportData = this.getYearReportData();

        return (
            <>
                {this.renderPaymentReminder()}
                <PDFViewer
                    productID={this.props.productId}
                    productLabel={this.props.productLabel}
                    pdfId={this.getRelevantPDF(value)}
                    primaryLanguage={this.props.value?.primaryLanguage}
                    secondaryDocuments={this.props.value?.secondaryDocuments}
                    internalPdfId={this.getRelevantInternalPDF(value)}
                    showInternalPDF={this.showInternalPDF()}
                    showSecondaryDocuments={this.userIsAdminOrAccountant()}
                    primaryPdfDocumentComponentsFileID={value.primaryPdfDocumentComponentsFileID}
                    internalPdfDocumentComponentsFileID={value.internalPdfDocumentComponentsFileID}
                    pdfHash={this.getHash(value)}
                    taxYear={this.props.taxYear}
                    updating={updating}
                    isLocked={isLocked}
                    regeneratePDFDocument={this.regenerateDocument}
                    isFlowContextNodePresent={this.isFlowContextNodePresent()}
                    flowContext={this.getFlowContextFact()?.value}
                    updateFlowContext={this.updateFlowContext}
                    canOverwriteAssemblyDate={this.canOverwriteAssemblyDate()}
                    assemblyDate={this.getAssemblyDate()}
                    yearReportDataNodeID={yearReportData.id}
                    yearReportData={yearReportData.data}
                    setAssemblyDate={this.setAssemblyDate}
                    downloadDisabled={!hasPayed || isAkademi(user)}
                    downloadDisabledMessage={!hasPayed ? 'Køb produktet for at downloade den fulde PDF' : null}
                    date={this.props.value.updatedAt}
                    isFirstYear={this.props.currentPeriod?.isFirstYear}
                    downloadXBRLFiles={this.downloadXBRLFiles}
                    canReportBeSetAsSigned={this.canReportBeSetAsSigned}
                    setReportAsSigned={this.setReportAsSigned}
                />
            </>
        );
    };

    render() {
        const { isRunning, fieldOptions, updating, userData } = this.props;
        const widgetVariant = fieldOptions[DocGenOptionName];

        switch (widgetVariant) {
            case DocGenVariantPDF: {
                return (
                    <FieldGroup title='Årsrapport' collapsible defaultOpen={true} unwrapped>
                        {this.renderContent()}
                    </FieldGroup>
                );
            }

            case DocGenVariantIXBRL: {
                return (
                    <IXBRLViewer
                        yearReportData={this.getYearReportData()}
                        regenerateDocument={this.regenerateDocument}
                        assemblyDate={this.getAssemblyDate()}
                        setAssemblyDate={this.setAssemblyDate}
                        flowContext={this.getFlowContextFact()?.value}
                        updateFlowContext={this.updateFlowContext}
                        {...this.props}
                    />
                );
            }

            case DocGenVariantDownloadLink: {
                return (
                    <DocgenDownloadLink
                        {...this.props}
                        regenerateDocument={this.regenerateDocument}
                    />
                );
            }

            case DocGenVariantSignflow: {
                if (updating && !isRunning) {
                    return null;
                }

                return (
                    <EsignaturSegment
                        {...this.props}
                        flowContext={this.getFlowContextFact()}
                    />
                );
            }

            default: {
                return (
                    <Segment color='red'>
                        <Icon name='warning sign' color='red' /> <b>Der opstod en fejl</b>
                        {userData.isAdmin() && <span>:&nbsp;Du har ikke valgt en doc-gen type i modellen</span>}
                    </Segment>
                );
            }
        }
    }
}

export default compose(
    withUserData,
    withRouter,
)(DocumentGenerator);
