import { useEffect, useMemo, useState } from "react";
import { Button, Icon, Input, Loader, Modal, Popup, Table } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ADMIN_LOCATION } from "config/config";
import { getModelNodes } from "http/modelEditor";
import { formatNumber } from "util/format/Number";
import useUser from "util/useUser";
import ColoredText from "design/atoms/ColoredText";
import CopyToClipboard from "design/atoms/CopyToClipboard";
import ObjectExplorer from "design/atoms/ObjectExplorer";
import { useBreakpoints } from "design/atoms/Media";
import styles from './DebugTools.module.scss';
import ContextMenu from "design/atoms/ContextMenu";

const makeEllipsisStyle = pixels => {
    return {
        maxWidth: pixels + 'px',
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };
};

const getFactsWithDebugInfo = async prodctMetadata => {
    const modelNodes = await getModelNodes(prodctMetadata.id);

    const timesSeenNodeID = {};
    const factsWithDebugInfo = [];

    for (const productFact of Object.values(prodctMetadata.facts)) {
        const associatedNode = modelNodes.find(node => {
            return productFact.id.includes(node.id);
        });

        if (!associatedNode) continue;

        timesSeenNodeID[associatedNode.id] ||= 0;
        timesSeenNodeID[associatedNode.id]++;

        factsWithDebugInfo.push({
            label: associatedNode.name,
            productFact,
            associatedNode,
        });
    }

    // nodes can export more than one fact
    // so we add more information to the label
    // of debug facts where there is a name collision
    for (const factWithDebugInfo of factsWithDebugInfo) {
        const timesSeen = timesSeenNodeID[factWithDebugInfo.associatedNode.id] || 0;
        if (timesSeen <= 1) continue;

        // extracts the part of the exported fact that explains what the fact contains (by stripping of the node ID)
        const extraLabelInfo = factWithDebugInfo.productFact.id.replace(factWithDebugInfo.associatedNode.id, ''); 

        factWithDebugInfo.label += extraLabelInfo;
    }

    return factsWithDebugInfo;
};

const ComplexValueViewer = ({ title, value }) => {
    const [open, setOpen] = useState(false);

    let asJSON;
    try {
        asJSON = JSON.stringify(value);
    } catch {
        return null;
    }

    return (
        <>
            <ColoredText
                link
                content='Se rå data'
                icon='eye'
                iconPosition='left'
                color='green'
                underlined={false}
                onClick={() => setOpen(true)}
            />
            <Modal open={open} closeIcon onClose={() => setOpen(false)}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content scrolling>
                    <ObjectExplorer levelsOpen={0} object={value} />
                </Modal.Content>
                <Modal.Actions>
                    <CopyToClipboard
                        text={asJSON}
                        trigger={
                            <Button
                                content='Kopiér'
                                icon='copy'
                            />
                        }
                    />
                    <Button color='black' content='Luk' onClick={() => setOpen(false)} />
                </Modal.Actions>
            </Modal>
        </>
    );
};

const DebugToolsMenu = ({ productMetadata, factMap, executionErrors, disableDebugTools }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState(window.sessionStorage.debugToolsQuery || '');
    const [debugFacts, setDebugFacts] = useState(null);
    
    const user = useUser();

    useEffect(() => {
        window.sessionStorage.debugToolsQuery = query;
    }, [query]);

    useEffect(() => {
        if (debugFacts) return;
        if (!isOpen) return;

        getFactsWithDebugInfo(productMetadata, factMap)
            .then(debugFacts => setDebugFacts(debugFacts))
            .catch(e => {
                console.error('Debug tools error:', e);
                toast.error('Kunne ikke indlæse debug tool...');
            });
    }, [productMetadata, factMap, debugFacts, isOpen]);

    const executionErrorMessages = Object.entries(executionErrors || {}).map(([factID, { errorMessage }]) => {
        if (!errorMessage) return null;

        return {
            factID,
            errorMessage,
        };
    }).filter(error => !!error);
    
    const searchResult = useMemo(() => {
        if (!query) return [];
        if (!debugFacts) return [];

        const lowerCaseQuery = query.toLowerCase();

        return debugFacts.filter(({ label, productFact, associatedNode }) => {
            const { tag, id } = productFact;

            if (label.toLowerCase().includes(lowerCaseQuery)) {
                return true;
            }

            if (tag && tag.toLowerCase().includes(lowerCaseQuery)) {
                return true;
            }

            if (id.toLowerCase().includes(lowerCaseQuery)) {
                return true;
            }

            if (associatedNode && associatedNode.type.toLowerCase().includes(query)) {
                return true;
            }
            
            const factMapValue = factMap[productFact.id];
            if (factMapValue && JSON.stringify(factMapValue.value).toLowerCase().includes(query)) {
                return true;
            }

            return false;
        });
    }, [query, debugFacts, factMap]);

    const renderContent = () => {
        if (!isOpen) {
            return (
                <Popup
                    offset={[5, 15]}
                    position='right center'
                    open={executionErrorMessages.length > 0}
                    trigger={<Icon name='bug' />}
                    content={`Der blev fundet ${executionErrorMessages.length} fejl!`}
                />
            );
        }
        
        const header = (
            <div style={{ display: 'flex', marginBottom: '1em' }}>
                <div style={{ flex: '1' }}>
                    <strong>
                        <Icon name='bug' /> Debug tools
                    </strong>
                </div>
                <div>
                    <Icon name='x' link onClick={() => setIsOpen(false)} />
                </div>
            </div>
        );

        const renderFactValue = (title, associatedNode, factMapValue) => {
            if (associatedNode.dataType === 'NonPrimitive') {
                return <ComplexValueViewer title={title} value={factMapValue.value} />;
            }

            if (associatedNode.dataType === 'StringList') {
                const value = factMapValue.value?.stringList?.join(', ');
                return <span title={value}>{value}</span>
            }

            if (associatedNode.dataType === 'Number') {
                return <span>{formatNumber(factMapValue?.value?.number || 0)}</span>
            }

            const firstFactVal = Object.values(factMapValue.value)[0];
            if (typeof firstFactVal === 'string') {
                if (/^(-?)[0-9]+/.test(firstFactVal)) {
                    return <span>{formatNumber(firstFactVal)}</span>;
                }
                
                return <span title={firstFactVal}>{firstFactVal}</span>;
            }

            if (typeof firstFactVal === 'boolean') {
                return <span>{firstFactVal ? 'Ja' : 'Nej'}</span>;
            }

            return <i>Kan ikke vise af typen {associatedNode.dataType}...</i>;
        };

        const renderedSearchResult = searchResult.slice(0, 10).map(({ label, productFact, associatedNode }) => {
            const factMapValue = factMap[productFact.id];

            let value;
            let hidden;
            let updatedAt;
            if (factMapValue) {
                value = renderFactValue(label, associatedNode, factMapValue);
                hidden = <span>{factMapValue.hidden ? 'Ja' : 'Nej'}</span>;
                updatedAt = <span>{new Date(factMapValue.updatedAt).toLocaleString()}</span>;
            } else {
                value = <span>-</span>;
                hidden = <span>-</span>;
                updatedAt = <span>-</span>;
            }
            return (
                <Table.Row key={productFact.id}>
                    <Table.Cell style={{ ...makeEllipsisStyle(400) }}>
                        <ContextMenu
                            options={[
                                {
                                    text: 'Kopiér query',
                                    onSelect: () => window.navigator.clipboard.writeText(JSON.stringify({
                                        id: associatedNode.id,
                                        productId: productMetadata.id,
                                        uid: user.id,
                                    }, '', '  ')),
                                },
                            ]}
                        >
                            {open => {
                                return (
                                    <CopyToClipboard
                                        text={associatedNode.id}
                                        trigger={(
                                            <Icon
                                                name='copy'
                                                link
                                                onContextMenu={e => {
                                                    open(e);
                                                }}
                                            />
                                        )}
                                    />
                                );
                            }}
                        </ContextMenu>
                        <ColoredText
                            link={`${ADMIN_LOCATION}/model-editor/${associatedNode.modelID}/node/${associatedNode.id}`}
                            newWindow={true}
                            content={label}
                            title={label}
                        />
                    </Table.Cell>
                    <Table.Cell style={{ ...makeEllipsisStyle(200) }}>{value}</Table.Cell>
                    <Table.Cell style={{ ...makeEllipsisStyle(100) }}>{hidden}</Table.Cell>
                    <Table.Cell style={{ ...makeEllipsisStyle(200) }}>{updatedAt}</Table.Cell>
                </Table.Row>
            );
        });

        const renderExectutionErrors = () => {
            if (executionErrorMessages.length === 0) return null;

            return (
                <>
                    <strong>
                        <Icon name='warning sign' color='red' /> Fejlbeskeder
                    </strong>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Node</Table.HeaderCell>
                                <Table.HeaderCell>Fejlbesked</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        {executionErrorMessages.map(({ errorMessage, factID }) => {
                            const associatedNode = debugFacts.find(fact => {
                                return fact.associatedNode.id === factID;
                            });

                            return (
                                <Table.Row error>
                                    <Table.Cell>{associatedNode?.label || factID}</Table.Cell>
                                    <Table.Cell>{errorMessage}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table>
                </>
            );
        };

        const isLoading = debugFacts === null;

        return (
            <div>
                {header}
                {isLoading && (
                    <div style={{ textAlign: 'center', width: '200px' }}>
                        <Loader inline active />
                        <div>
                            Indlæser modeldata...
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <>
                        <Input
                            fluid
                            icon='search'
                            iconPosition='left'
                            placeholder='Søg efter noder...'
                            defaultValue={query}
                            onChange={(_, { value }) => setQuery(value)}
                        />
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell style={{ width: '400px' }}>Node</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '200px' }}>Værdi</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '100px' }}>Er skjult?</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '200px' }}>Sidst opdateret</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {renderedSearchResult}
                                {renderedSearchResult.length === 0 && (
                                    <Table.Row>
                                        <Table.Cell colSpan={4}>
                                            <i>Ingen resultater fundet...</i>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        {renderExectutionErrors()}
                        <ColoredText
                            link
                            float='right'
                            icon='eye slash'
                            content='Skjul debug tools ind til appen genstartes'
                            underlined={false}
                            onClick={disableDebugTools}
                        />
                    </>
                )}
            </div>
        );
    };

    return (
        <div
            className={isOpen ? undefined : styles.noselect}
            onClick={isOpen ? undefined : () => setIsOpen(true)}
            children={renderContent()}
            style={{
                cursor: isOpen ? undefined : 'pointer',
                boxShadow: 'rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
                border: '1px solid lightgray',
                borderBottomLeftRadius: '1em',
                background: 'white',
                position: 'fixed',
                padding: '1em',
                top: '0',
                right: '0',
                zIndex: 20,
            }}
        />
    );
};

const DebugTools = props => {
    const user = useUser();
    const breakpoints = useBreakpoints();
    const [isDisabledForSession, setIsDisabledForSession] = useState(!!window.sessionStorage.drDevToolsDisabled);

    const disableDebugTools = () => {
        window.sessionStorage.drDevToolsDisabled = true;
        setIsDisabledForSession(true);
    };

    if (isDisabledForSession) {
        return null;
    }

    if (breakpoints.isMobile) {
        return null;
    }

    if (!user.isAdmin()) {
        return null;
    }

    return <DebugToolsMenu disableDebugTools={disableDebugTools} {...props} />
};

export default DebugTools;