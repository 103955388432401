import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Modal, Popup } from 'semantic-ui-react';

const EditReportTextModal = ({ onSave, onReset, onClose, textBeingEdited, hasBeenEdited }) => {
    const [newText, setNewText] = useState(textBeingEdited.defaultValue);

    const handleSave = () => {
        const textHasBeenChanged = newText !== textBeingEdited.defaultValue;
        if (!textHasBeenChanged) {
            onClose();
            return;
        }

        onSave([{ text: newText }]);
        onClose();
    };

    return (
        <Modal open onClose={onClose}>
            <Modal.Content>
                <strong>Redigér text</strong>
                <br />
                <textarea
                    style={{ width: '100%', height: '200px' }}
                    defaultValue={newText}
                    onChange={e => setNewText(e.target.value)}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button content='Annuller' color='black' onClick={onClose} />
                {hasBeenEdited && (
                    <Button
                        content='Nulstil'
                        color='black'
                        icon='undo'
                        onClick={() => {
                            onReset();
                            onClose();
                        }}
                    /> 
                )}
                <Button
                    primary
                    content='Gem'
                    icon='save'
                    onClick={handleSave}
                />
            </Modal.Actions>
        </Modal>
    );
};

const ReportTextEditor = ({ metadata, onClose, onSave, hasBeenEdited }) => {
    const textBeingEdited = useMemo(() => {
        return {
            defaultValue:  metadata.textTokens.map(token => token.text).join(''),
            editorID: metadata.editorID,
        };
    }, [metadata]);

    return (
        <EditReportTextModal
            textBeingEdited={textBeingEdited}
            hasBeenEdited={hasBeenEdited}
            onReset={() => {
                onSave({
                    editorID: metadata.editorID,
                    namespace: 'documentComponentOverrides',
                    shouldDelete: true,
                });
            }}
            onSave={tokens => {
                onSave({
                    editorID: metadata.editorID,
                    namespace: 'documentComponentOverrides',
                    newData: { text: tokens },
                });
            }}
            onClose={onClose}
        />
    );
};

const NoteVisibilityEditor = ({ onClose, children, metadata, onSave, buildingBlockOverrides }) => {
    const [overrideProp] = metadata.activeBuildingBlockOverrides;

    const label = {
        forceShowNote: 'Vis note',
        forceHideNote: 'Skjul note',
    }[overrideProp];

    if (!label) {
        throw new Error('Unknown NoteVisibilityEditor override property: ' + overrideProp);
    }

    const checkbox = (
        <Checkbox
            label={label}
            defaultChecked={(buildingBlockOverrides || {})[overrideProp]}
            onChange={(_, { checked }) => {
                onSave({
                    editorID: metadata.editorID,
                    namespace: 'buildingBlockOverrides',
                    newData: { [overrideProp]: checked },
                });
            }}
        />
    );

    return (
        <Popup
            on='click'
            position='top center'
            open
            basic
            onClose={onClose}
            trigger={children}
            content={checkbox}
        />
    );
};

const Editor = ({ metadata, children, ...props }) => {
    const EditorComponent = {
        text: ReportTextEditor,
        toggleNoteVisibility: NoteVisibilityEditor,
    }[metadata.editorKind];

    if (!EditorComponent) {
        return children;
    }

    return (
        <EditorComponent metadata={metadata} {...props}>
            {children}
        </EditorComponent>
    );
};

export default Editor;