import React from 'react';
import { Grid, Icon, Message } from 'semantic-ui-react';
import { userStatusesEnum } from 'design/molecules/AppLauncher/userStatuses';
import UnlockModal from 'design/pages/Product/components/LockStatus/UnlockModal';
import ColoredText from 'design/atoms/ColoredText';
import withUserData from 'util/withUserData';
import { isErhverv } from 'util/userMethods';

class LockStatus extends React.Component {
    getTaxReportedText = () => {
        if (isErhverv(this.props.userData)) {
            return 'Sådan! Din rapport er afsluttet. Du kan derfor ikke rette i dine indtastede oplysninger';
        }

        return 'Sådan! Du har indberettet på skat.dk og kan derfor ikke rette i dine indtastede oplysninger';
    };

    renderTaxReportedLockMessage = () => (
        <Message
            color='green'
            content={
                <span>
                    <Icon name='lock' />
                    {this.getTaxReportedText()}
                </span>
            }
        />
    );

    renderSignatureLockMessage = () => (
        <Message
            content={
                <Grid stackable columns={2}>
                    <Grid.Column>
                        Indtastning er låst, da din rapport er sendt til underskrift
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <UnlockModal
                            onUnlock={this.props.onUnlock}
                            trigger={(
                                <ColoredText
                                    link
                                    content='Lås op'
                                    icon='unlock'
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid>
            }
        />
    );

    render () {
        switch (this.props.status) {
            case userStatusesEnum.REPORTED:
                return this.renderTaxReportedLockMessage();
            case userStatusesEnum.SIGNATURE_FLOW:
            case userStatusesEnum.VISMA_SIGNATURE_FLOW:
                return this.renderSignatureLockMessage();
            case userStatusesEnum.ESIGNATUR_FLOW:
                return this.renderSignatureLockMessage();
            case userStatusesEnum.ERP_DATA_FETCHED:
            case userStatusesEnum.STARTED:
            case userStatusesEnum.NOT_STARTED:
            case userStatusesEnum.COMPLETED:
            case userStatusesEnum.DONE:
            default:
                return null;
        }
    }
}

export default withUserData(LockStatus);
