import { HTTPClient } from '../network/fetch';

const client = new HTTPClient({ prefix: 'esignatur' });

export async function getFlowSummary(flowId, forceUpdate = false) {
    return client.getResource(`flow/${flowId}/summary?forceUpdate=${forceUpdate}`);
}

export function sendReminderBySigneeReference(flowId, signeeReference) {
    return client.getResource(`flow/${flowId}/signees/${signeeReference}/send-reminder`)
}

export function updateEmail(flowId, signeeReference, oldEmail, newEmail) {
    return client.postResource(`flow/${flowId}/signees/${signeeReference}/update-email`, {
        oldEmail,
        newEmail,
    });
}

export function getSignedDocument(flowId, documentID) {
    return client.getResource(`flow/${flowId}/signed-documents/${documentID}`);
}

export function getAttachment(flowId, documentID) {
    return client.getResource(`flow/${flowId}/attachments/${documentID}`);
}