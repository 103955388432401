import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { Flag, Icon, Popup, Transition } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import useUser from 'util/useUser';
import ColoredText from 'design/atoms/ColoredText';
import { downloadIXBRLDocument } from './downloadIXBRL';
import InlineLogoPicker from './InlineLogoPicker';
import Editor from './Editor';
import styles from './VirtualHTMLDocument.module.scss';

const InlineAssemblyDateForm = ({ children, renderAssemblyDateForm, disabled }) => {
    const [open, setOpen] = useState(false);

    return (
        <Popup
            basic
            on='click'
            open={open}
            disabled={disabled}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            position='top center'
            content={(
                <div style={{ width: '300px' }}>
                    {renderAssemblyDateForm({ onDatePicked: () => setOpen(false) })}
                </div>
            )}
            trigger={(
                <span
                    className={disabled ? undefined : styles.editableText}
                    onClick={() => setOpen(true)}
                    style={{
                        cursor: disabled ? undefined : 'pointer',
                    }}
                >
                    {children}
                </span>
            )}
        />
    );
};

const PageOverlay = ({ children, userMayEdit, overrideConfiguration, pageID, onOverwrite, disabled, isFirstPage, flowContext, updateFlowContext, selectedDocument }) => {
    const [hovered, setHovered] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const user = useUser();

    const pageIsHidden = get(overrideConfiguration, `buildingBlockOverrides.${pageID}.hidden`) || false;

    const setHidden = hidden => {
        setMenuOpen(false);
        onOverwrite({
            editorID: pageID,
            namespace: 'buildingBlockOverrides',
            newData: { hidden },
        });
    };

    useEffect(() => {
        if (!hovered) setMenuOpen(false);
    }, [hovered]);

    const ellipsisMenu = (
        <Popup
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            on='click'
            position='left center'
            trigger={<Icon name='ellipsis horizontal' link size='large' />}
            content={(
                <div>
                    <ColoredText
                        underlined={false}
                        onClick={() => setHidden(!pageIsHidden)}
                        icon={pageIsHidden ? 'eye' : 'eye slash'}
                        content={pageIsHidden ? 'Vis side' : 'Skjul side'}
                        iconPosition='left'
                        link
                    />
                </div>
            )}
        />
    );

    const renderLanguageMenu = () => {
        if (!isFirstPage) {
            return null;
        }

        if (disabled) {
            return null;
        }

        const languageItems = [
            { code: 'da-DK', flag: 'dk', content: 'Dansk' },
            { code: 'en-GB', flag: 'gb', content: 'Engelsk' },
        ];

        const chosenLang = flowContext?.languageContext?.primaryLanguage || 'da-DK';
        const chosenLangItem = languageItems.find(item => item.code === chosenLang);

        return (
            <div style={{ display: 'inline-block' }}>
                <div
                    style={{
                        background: 'rgba(1, 1, 1, 0.05)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5em',
                        padding: '1em',
                        paddingBottom: '0.75em',
                        paddingRight: '0.75em',
                        borderBottom: '1px solid lightgray',
                        borderRight: '1px solid lightgray',
                        borderBottomRightRadius: '4px',
                    }}
                >
                    {languageItems.map(langItem => {
                        const isActive = chosenLangItem === langItem; 
                        return (
                            <Flag
                                name={langItem.flag}
                                onClick={() => updateFlowContext({
                                    languageContext: { primaryLanguage: langItem.code },
                                })}
                                style={{
                                    opacity: isActive ? 1 : 0.5,
                                    cursor: isActive ? undefined : 'pointer',
                                    filter: isActive ? undefined : 'grayscale(50%)'
                                }}
                            />
                        );
                    })}
                    <div style={{ borderLeft: '1px solid lightgray', paddingLeft: '0.75em' }}>
                        <Icon
                            onClick={() => downloadIXBRLDocument(selectedDocument, user)}
                            name='download'
                            color='grey'
                            link
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {children}

            {isFirstPage && (
                <div style={{ position: 'absolute', top: '0', left: '0' }}>
                    {renderLanguageMenu()}
                </div>
            )}

            {!disabled && (
                <Transition visible={hovered} animation='fade down' duration={150}>
                    <div style={{ position: 'absolute', top: '0em', right: '0em' }}>
                        <div style={{ padding: '1em' }}>
                            {userMayEdit && ellipsisMenu}
                        </div>
                    </div>
                </Transition>
            )}
        </div>
    );
};

const VirtualHTMLDocument = ({ virtualHTMLDocument, selectedDocument, paymentURL, productIsLocked, triggerReportRegeneration, onOverwrite, overrideConfiguration, renderAssemblyDateForm, flowContext, updateFlowContext }) => {
    const history = useHistory();
    const user = useUser();
    const [activeEditorID, setActiveEditorID] = useState(null);

    const addClassName = (props, className) => {
        if (!props['class']) {
            props['class'] = className;
        } else {
            props['class'] += ` ${className}`;
        }
    };

    const hasClassName = (ele, ...classNamesToCheck) => {
        const elementClassName = ele['class'] || '';
        return (
            elementClassName
            .split(' ')
            .map(x => x.trim())
            .filter(x => x)
            .some(c => classNamesToCheck.includes(c))
        );
    };

    let hasRenderedPageBefore = false;

    const renderVirtualHTMLElement = virtualHTMLElement => {
        const {
            tag,
            style,
            children,
            metadata = {},
            ...other
        } = virtualHTMLElement;

        const props = {
            style,
            ...other,
        };

        const elementIsPage = hasClassName(props, 'page');
        if (elementIsPage && !productIsLocked) {
            addClassName(props, styles.editablePage);
        }

        const userMayEdit = user.isAdmin() || user.isAccountant();
        const elementCanBeEdited = 'editorKind' in metadata;
        
        let elementHasBeenEdited = false;

        if (elementCanBeEdited && userMayEdit && !productIsLocked) {
            props.id = metadata.editorID;
            props.onClick = () => setActiveEditorID(metadata.editorID);

            const documentComponentOverrides = (overrideConfiguration?.documentComponentOverrides || {})[metadata.editorID] || {};
            const buildingBlockOverrides = (overrideConfiguration?.buildingBlockOverrides || {})[metadata.editorID] || {};
            const hasActiveDocumentComponentOverrides = Object.values(documentComponentOverrides).some(value => !!value);
            const hasActiveBuildingBlockOverrides = Object.values(buildingBlockOverrides).some(value => !!value);

            elementHasBeenEdited = hasActiveDocumentComponentOverrides || hasActiveBuildingBlockOverrides;

            addClassName(props, elementHasBeenEdited ? styles.editedText : styles.editableText);
        }

        if (hasClassName(virtualHTMLElement, 'purchaseLink')) {
            props.onClick = () => history.push(paymentURL);
        }

        const convertedChildren = children.map(ele => {
            if (typeof ele === 'string') {
                return ele;
            }
            return renderVirtualHTMLElement(ele);
        });

        let element;
        if (convertedChildren.length === 0) {
            element = React.createElement(tag, props);
        } else {
            element = React.createElement(tag, props, convertedChildren);
        }

        if (metadata.editorID === activeEditorID) {
            const buildingBlockOverrides = (overrideConfiguration?.buildingBlockOverrides || {})[metadata.editorID];
            const documentComponentOverrides = (overrideConfiguration?.documentComponentOverrides || {})[metadata.editorID];

            return (
                <Editor
                    children={element}
                    metadata={metadata}
                    onSave={onOverwrite}
                    onClose={() => setActiveEditorID(null)}
                    hasBeenEdited={elementHasBeenEdited}
                    buildingBlockOverrides={buildingBlockOverrides}
                    documentComponentOverrides={documentComponentOverrides}
                />
            );
        }

        if (elementIsPage) {
            const isFirstPage = !hasRenderedPageBefore;
            hasRenderedPageBefore = true;
            return (
                <PageOverlay
                    userMayEdit={userMayEdit}
                    overrideConfiguration={overrideConfiguration}
                    onOverwrite={onOverwrite}
                    pageID={metadata.editorID}
                    children={element}
                    disabled={productIsLocked}
                    isFirstPage={isFirstPage}
                    flowContext={flowContext}
                    updateFlowContext={updateFlowContext}
                    selectedDocument={selectedDocument}
                />
            );
        }

        const isDateOfGeneralMeeting = virtualHTMLElement?.name?.endsWith('DateOfGeneralMeeting');
        if (isDateOfGeneralMeeting && userMayEdit) {
            return (
                <InlineAssemblyDateForm
                    renderAssemblyDateForm={renderAssemblyDateForm}
                    children={element}
                    disabled={productIsLocked}
                />
            );
        }

        if (hasClassName(virtualHTMLElement, 'emptyLogo', 'reportLogo')) {
            const isEmpty = hasClassName(virtualHTMLElement, 'emptyLogo');
            
            return (
                <InlineLogoPicker
                    isLocked={productIsLocked}
                    isEmpty={isEmpty}
                    trigger={element}
                    onLogoPicked={triggerReportRegeneration}
                />
            );
        }
        
        return element;
    };

    return (
        <>
            <div style={{ all: 'initial' }} id='documentWrapper'>
                {virtualHTMLDocument && renderVirtualHTMLElement(virtualHTMLDocument)}
            </div>
        </>
    );
};

export default VirtualHTMLDocument;